import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const baseUrl = `${mainUrl}/web-page-conf`

 const getAllPreguntas = async () => { 
  const {data: res} = await axios.get(`${baseUrl}/web/frequent-questions`);
  return res;
};

  const getPreguntaId = async (id) => { 
        const {data: res} = await axios.get(`${baseUrl}/web/frequent-questions/${id}`);
        return res;
    };

  const getGrouperId = async (id) => { 
      const {data: res} = await axios.get(`${baseUrl}/web/frequent-questions/grouper/${id}`);
      return res;
  };


  export default {
    getPreguntaId,
    getGrouperId,
    getAllPreguntas
  }

  