import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const baseUrl = `${mainUrl}/web-page-conf`


const getInformationbyID = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/catalog/information-institutional/${id}
    `);
    return res;
  };

  const getInformationbyDesc = async (desc) => { 
    const {data: res} = await axios.post(`${baseUrl}/web/catalog/information-institutional/getbydesc`,desc);
    return res;
  };

  const getAllInformationInst = async () => { 
    const {data: res} = await axios.get(`${baseUrl}/web/catalog/information-institutional`);
    return res;
  };

  export default {
    getInformationbyID,
    getAllInformationInst,
    getInformationbyDesc
  }