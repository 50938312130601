import React, { useState, useEffect } from 'react';
import { IconButton, } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Carousel } from 'react-responsive-carousel';
import InfoRequests from './Requests/InfoRequests'
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from "react-html-parser";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AddHelmet } from '../../components/Helmet/AddHelmet';

const options = [
  'Historia',
  'Mision',
  'Vision',
  'Valores',
];

function InfoInstitucional() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  let history = useHistory()
  let { name } = useParams();
  const [info, setInfo] = useState(null)
  const [fotos, setFotos] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState(name.replace('%2C', ','));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [data, setData] = useState([])
  const [dataSeo, setDataSeo] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
    getInfoData()
    getInfoInstbyID(name)
  }, [name]);

  const getInfoData = () => {
    try {
      InfoRequests.getAllInformationInst().then((res) => {
        setData(res)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, idInfo, index) => {
    setSelectedIndex(index);
    getInfoInstbyID(encodeURIComponent((idInfo.element == 'Informes Anuales' || idInfo.element == 'Presencia Nacional') ? idInfo.element : idInfo.url))
    setAnchorEl(null);

  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getInfoInst = async () => {
    try {
      InfoRequests.getAllInformationInst().then((e) => {
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getInfoInstbyID = async (idInfo) => {
    let info = idInfo.split('%2C').join(',');
    info = info.split('%20').join('');
    info = info.split(' ').join('');
    info = info.toUpperCase();

    if (info === "INFORMESANUALES") {
      history.push(`/informes_anuales`)
    } else if (info == "PRESENCIANACIONAL") {
      history.push(`/#map-section`)
    } else {
      history.push(`/nuestra_empresa/${idInfo}`)
      setFotos([])
      const json = { description: decodeURIComponent(idInfo) }
      setSelected(idInfo.split('%2C').join(','));
      try {
        InfoRequests.getInformationbyDesc(json).then((e) => {
          //console.log("InfoID",e)
          setInfo(e)
          setFotos(e.photo);
          setDataSeoStorage({
            meta_description: e.meta_description,
            meta_keywords: e.meta_keywords,
            title_page: e.title_page,
            url: e.url
          });
          // e.photo.map(item=>{
          //     setFotos(oldArray => [...oldArray,item])
          // })
        }).catch((error) => {
          console.log(error)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const arrowNext = () => (
    <IconButton
      size="large"
      style={{ backgroundColor: 'red', }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  )

  const setDataSeoStorage = (data) => {
    setDataSeo(data);
    window.sessionStorage.setItem("seoRA", JSON.stringify(data));
  }

  return (
    <div>
      <AddHelmet data={dataSeo}></AddHelmet>
      {matches ?
        <>
          {data.length > 0 &&
            <div>
              <List
                className="inner-dropdown-select"
                component="nav"
                aria-label="Device settings"
                sx={{ bgcolor: 'background.paper' }}
              >
                <ListItem
                  button
                  id="lock-button"
                  aria-haspopup="listbox"
                  aria-controls="lock-menu"
                  aria-label="when device is locked"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickListItem}
                >
                  <ListItemText primary={data[selectedIndex].element}/>
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox',
                }}
              >
                {data.map((option, index) => (
                  <MenuItem
                   style={{ color: 'black',display:'flex' }}
                    key={option.element}
                    selected={selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, option, index)}
                  >
                    {option.element}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          }
        </>
        :
        <div className="inner-top-bar w-full py-3">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 heading-toogle">
                {data && (
                  <>
                    {data.map((item, index) => (

                      <ListItem onClick={() => { getInfoInstbyID(encodeURIComponent((item.element == 'Informes Anuales' || item.element == 'Presencia Nacional') ? item.element : item.url)) }} style={selected == item.url ? selectedStyles : unselectedStyles}>
                        <ListItemText style={{ color: selected == item.url ? 'white' : null, cursor: 'pointer' }} disableTypography={true} className="text-center">{item.element}</ListItemText>
                      </ListItem >

                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

        </div>
      }
      <div className="w-full inner-page-container-white pb-12">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {info !== null || info !== undefined ?
                <>
                  <Carousel
                    showArrows={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles,
                          left: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowBackIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    renderArrowNext={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles2,
                          right: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    showIndicators={fotos.length > 1 ? true : false}
                    renderIndicator={(onClickHandler, isSelected, label) => (
                      <div className="inline-block items-center justify-center ml-8">
                        <ul
                          style={{ ...indicatorStyles, opacity: isSelected ? '1' : '0.7' }}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}>
                          <li style={{ color: isSelected ? "rgb(0, 61, 165)" : "white", fontSize: 50 }}></li>
                        </ul>
                      </div>

                    )}
                    autoPlay
                    interval={3000}
                    infiniteLoop
                    swipeable
                    //  renderArrowNext={arrowNext}
                    emulateTouch
                    showThumbs={false}
                    showStatus={false}>
                    {/* data.image */}
                    {fotos.map((data, index) => (
                      <div title={data.title} className="inner-section-carousel lazyload" data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${data.name_image.replace(/\s/g, "%20")}`}>

                      </div>
                    ))}
                  </Carousel>
                  <div className="my-4">
                    <h1>{info !== null ? info.element : null}</h1>
                  </div>
                  <div>
                    <p className="my-10 rich-text-editor">
                      {ReactHtmlParser(info !== null ? info.description : null)}
                    </p>
                  </div>
                </> :
                <CircularProgress />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const indicatorStyles = {
  display: 'inline-block',
  color: '#fff',
  cursor: 'pointer',
};

const selectedStyles = {
  backgroundColor: "rgb(0, 61, 165)",
  borderRadius: 31.5,
  color: 'white'
}

const arrowStyles = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};

const arrowStyles2 = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};

const unselectedStyles = {
  color: 'black'
}

export default InfoInstitucional