import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import RequestCommon from '../RequestCommon';

export const AddHelmet = ({ data }) => {

  let title = 'Red Ambiental';
  let description = 'Red Ambiental es la empresa líder en transportación y manejo integral de residuos que sirve al medio ambiente y a la población de México.';
  const [dataSeo, setDataSeo] = useState({});

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.toString() === "/") {      
      getSeoMetaByCode("I");
    } else if (location.pathname.toLocaleLowerCase().indexOf("cotizacion") > -1) {
      getSeoMetaByCode("C");
    } else {
      handleSetSeo();
    }

    console.log("Entra cambio ruta");

    
  }, [location.pathname]);

  useEffect(() => {
    handleSetSeo();
    console.log("entra pros", dataSeo)
  }, [data]);

  const handleSetSeo = () => {
    let stringSeo = window.sessionStorage.getItem("seoRA");

    if (stringSeo !== 'undefined' && stringSeo !== 'null' && stringSeo !== '' && stringSeo !== null) {
      setDataSeo(JSON.parse(stringSeo));
    } else {
      setDataSeo({title_page: title, meta_description: description});
    }
  }

  const getSeoMetaByCode = async (code) => {
    try {
      RequestCommon.getSEO(code).then((res) => {
        window.sessionStorage.setItem("seoRA", JSON.stringify(res));
        handleSetSeo();
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Helmet>
      <title>{dataSeo?.title_page}</title>
      <meta name="description" content={dataSeo?.meta_description} />
      <meta name="keywords" content={dataSeo?.meta_keywords} />
    </Helmet>
  )
}
