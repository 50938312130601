
export const getModules = (key) => {
    const map = new Map();
    map.set('Blog', '/Blog_Detalle/name');
    map.set('Sala Prensa', '/detalle_prensa/name');
    map.set('Bolsa Trabajo', '/vacantes');
    map.set('Sustentabilidad', '/resp_detalle/id');
    
    return map.get(key);
}
