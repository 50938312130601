import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL 
const baseUrl = `${mainUrl}/web-page-conf`


  const getLastNew = async () => { 
        const {data: res} = await axios.get(`${baseUrl}/web/blog/getLast`);
        return res;
    };


  export default {
    getLastNew,
  }