import React from 'react';
import ReactDOM from 'react-dom';
import './css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RedAmbiental } from './RedAmbiental';

ReactDOM.render(
  <React.StrictMode>
    <RedAmbiental />
  </React.StrictMode>,
  document.getElementById('root')
);

