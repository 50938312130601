import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
 const baseUrl = `${mainUrl}/web-page-conf`


  const getAllClients = async () => { 
        const {data: res} = await axios.get(`${baseUrl}/web/clients/getAll`);
        return res;
    };

  const getRespById = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/social-responsability/${id}`);
    return res;
  };

  export default {
    getRespById,
    getAllClients,
  }