import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL 
const baseUrl = `${mainUrl}/web-page-conf`


  const getAlllNews = async () => { 
        const {data: res} = await axios.get(`${baseUrl}/web/vacancies/getall`);
        return res;
    };
    const getAllStates = async () => { 
      const {data: res} = await axios.get(`${baseUrl}/web/vacancies/getAllStates`);
      return res;
  };

  const getNewID = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/vacancies/getall/${id}`);
    return res;
  };

  export default {
    getNewID,
    getAlllNews,
    getAllStates
  }