import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import Requests from './Requests/Requests';

function Clientes() {

    const [data, setData] = useState([])
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL

    const getAllClient = async () => {
        try {
          Requests.getAllClients().then((e)=>{
            setData(e)
        }).catch((error)=>{
            console.log(error)
        })
        } catch (error) {
            console.log(error)
        }
      }

      useEffect(()=>{
        getAllClient()
         },[]);
    var settings = {
        dots: true,
        infinite: data.length > 4,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
    return (
        <section className="section-home-clients background-clients">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="heading-title mb-5">Ellos ya confían en nosotros</h2>
                    </div>
                    <div className="col-sm-12">
                        <div className="clients-slider-container">
                            <Slider {...settings}>
                              {data.map((e)=>(
                                      // <LazyLoad height={200}>

                                <img key={`clientes${e.id}`} className="slide-img lazyload" data-src={`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_image.replace(" ", "%20" )}`} alt={e.alt} title={e.title} width="calc(33% - 50px / 3)" height="calc(33% - 50px / 3)"/>
                                // </LazyLoad>

                              ))}
                            </Slider>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="divider"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Clientes
