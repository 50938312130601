import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from "@mui/material/Button";

export const CotizadorBoton = () => {
    let history = useHistory();
    const [showBtn, setShowBtn] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.indexOf("solicita_cotizacion") > -1) {
            setShowBtn(false);
        } else {
            setShowBtn(true);
        }
    }, [location.pathname])


    return (
        <>
            {
                showBtn &&
                <Button className="button-newRequest" onClick={() => { history.push('/solicita_cotizacion') }}>Solicita tu cotización</Button>
            }
        </>
    )
}
