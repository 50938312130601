import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Requests from './request/Requests';
import ReactPlayer from 'react-player'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ReactHtmlParser from "react-html-parser";
import { Carousel } from "react-responsive-carousel";
import iconMarker from './icon-01.png'
import Slider from "react-slick";
import lnegocioRequest from "./request/lnegocioRequest";
import { AddHelmet } from '../Helmet/AddHelmet';
function DetalleLinea() {
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    const history = useHistory();
    const { name } = useParams();
    const [data, setData] = useState()
    const [dataa, setDataa] = useState([])
    const [data2, setData2] = useState([])
    const [datax, setDatax] = useState([])
    const [datax2, setDatax2] = useState()
    const [datax3, setDatax3] = useState([])
    const [datax4, setDatax4] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [selectedIndex2, setSelectedIndex2] = useState(1);
    const [noContent, setNoContent] = useState(false)
    const [noticia, setNoticia] = useState([])
    const [noVideos, setNoVideos] = useState(false)
    const [sucursales, setSucursales] = useState(false)
    const [dataSeo, setDataSeo] = useState({});

    const handleListItemClick = (event, index, item) => {
        setSelectedIndex(index);
    };
    const handleListItemClick2 = (event, index) => {
        setSelectedIndex2(index);
    };   

    var settings = {
        dots: true,
        infinite: datax4.length > 4,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        Requests.getByRoute(name).then((e) => {
            setData(null);
            setData(e)
            console.log('E----------',e);
            //console.log('clo',e)
            setDatax(e)
            setDatax3(e.business_unit)
            if (e.list_clients.length > 0) {
                setDatax4(e.list_clients)
            } else { setDatax4([]) }
            if (e.list_videos.length > 0) {
                setNoticia(e.list_videos)
                setNoVideos(true)
            } else { setNoVideos(false) }
            if (e.list_services.length < 1) {
                setNoContent(true)
            } else { setDatax2(e.list_services[0]) }
            if (e.business_unit.length > 0) {
                setSucursales(true)
            } else { setSucursales(false) }

            if (e.list_services.length < 1 || e.list_services == null) {
                setNoContent(true)
            } else { setNoContent(false); }


            if (e.list_services.length >= 1) {
                setData2(e.list_services)

            } else { setData2(null); setNoContent(true) }
            setSelectedIndex2(e.list_services[0]?.id_services)
        }).catch((error) => {
            console.log(error)
        })
        lnegocioRequest.getAllBusinessLine().then((a) => {
            setDataa(a)
        })

        setSelectedIndex(name);
    }, [name]);

    const setDataSeoStorage = (data) => {
        setDataSeo(data);
        window.sessionStorage.setItem("seoRA", JSON.stringify(data));
      }


    return (
        <div>
            <AddHelmet data={dataSeo}></AddHelmet>
            <div className="inner-top-bar w-full py-3">
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-12 heading-toogle">
                            {dataa.map((item) => (
                                <List key={item.id}>
                                    <ListItem button style={selectedIndex == item.url ? selectedStyles : UnselectedStyles}
                                        selected={selectedIndex === item.url}
                                        onClick={(event) => { history.push(`/detalle_linea/${item.url}`); handleListItemClick(event, item.route, item); setDataSeoStorage({
                                            meta_description: item.meta_description,
                                            meta_keywords: item.meta_keywords,
                                            title_page: item.title_page,
                                            url: item.url
                                          }); }}>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                </List>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {data &&
                <>
                    <Carousel
                        showArrows
                        // autoPlay
                        // interval={3000}
                        infiniteLoop
                        swipeable
                        emulateTouch
                        showIndicators={data.list_imagenes.length > 1 ? true : false}
                        showThumbs={false}
                        showStatus={false}>
                        {data.list_imagenes.length > 0 ?
                            data.list_imagenes.map((item, index) => (
                                <div key={index} className="slider-businessLines lazyload" data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_image}`} title={item.title}>
                                </div>
                            ))
                            :
                            <div className="slider-businessLines lazyload" data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${data.name_cover_image}`} title={data.title}>
                            </div>}
                    </Carousel>
                    <section className="service-inner-descript">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>{ReactHtmlParser(data.name)}</h1>
                                </div>
                                <div className="col-sm-12">
                                    <p className='rich-text-editor'>{ReactHtmlParser(data.description_2)}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {noContent === true ? null :
                        <section className="service-inner-services">
                            <div className="container">
                                <div className="row">
                                    <div className='col-sm-3 mt-5'>
                                        <h2 >Tipos de servicios</h2>
                                        {data2 === null ?
                                            null :
                                            data2.map((item, index) => (
                                                <List key={item.id_services} style={{ backgroundColor: 'white' }}>
                                                    <ListItem button style={selectedIndex2 == item.id_services ? selectedStyles2 : UnselectedStyles2}
                                                        selected={selectedIndex2 === item.id_services}
                                                        onClick={(event) => { setDatax2(data.list_services[index]); setSelectedIndex2(item.id_services); }}>
                                                        <ListItemText primary={item.name} />
                                                    </ListItem>
                                                </List>
                                            ))
                                        }
                                    </div>


                                    {datax2 &&
                                        <div className='col-sm-8 offset-sm-1' style={{ flexDirection: 'column', display: 'flex' }}>
                                            {datax2.image_services.length > 0 &&
                                                <Carousel
                                                className='mb-4'
                                                dynamicHeight
                                                showArrows
                                                autoPlay
                                                interval={3000}
                                                infiniteLoop
                                                swipeable
                                                emulateTouch
                                                showIndicators={datax2.image_services.length > 1 ? true : false}
                                                showThumbs={false}
                                                showStatus={false}>
                                                {
                                                    datax2.image_services.length > 0 ?
                                                        datax2.image_services.map((item, index) => (
                                                            <img alt={item.alt} title={item.title} key={index} src={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_image.replace(/\s/g, "%20")}`} style={{ width: '100%', height: 'auto', backgroundSize: 'cover', backgroundPosition: 'center', marginTop: 25 }}></img>
                                                        ))
                                                        :
                                                        <img alt={datax2.alt} title={datax2.title} src={`${mainUrl}/azure-storage/api/blob/download/?file=${datax2.name_image.replace(/\s/g, "%20")}`} style={{  width: '100%', height: 'auto', backgroundSize: 'cover', backgroundPosition: 'center', marginTop: 25 }}></img>
                                                }
                                            </Carousel>}

                                            <h2>{datax2.name}</h2>
                                            <p className='rich-text-editor'>{ReactHtmlParser(datax2.description)}</p>
                                        </div>}

                                </div>
                            </div>
                        </section>}
                    {datax3.length < 1 ? null :
                        datax3 &&
                        <section className="branches-inner-page mb-5">

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6 col-md-3 col-lg-3">
                                        <h2>Nuestras sucursales</h2>
                                    </div>
                                    <div className="col-sm-6 col-md-9 col-lg-9">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {datax3.map((e, index) => (
                                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                                        <div className="branch-button" key={index} onClick={() => {
                                                            setDataSeoStorage({
                                                                meta_description: e.meta_description,
                                                                meta_keywords: e.meta_keywords,
                                                                title_page: e.title_page,
                                                                url: e.url
                                                              });
                                                               history.push(`/sucursales/${encodeURIComponent(e.url)}`); }}>
                                                            <img className="" src={iconMarker} />
                                                            <h4>{e.name}</h4>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>}
                    {noVideos == true ?
                        <section className="service-inner-video mt-10" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Carousel
                                            showArrows
                                            autoPlay
                                            interval={3000}
                                            infiniteLoop
                                            swipeable
                                            dynamicHeight
                                            emulateTouch
                                            showThumbs={false}
                                            showStatus={false}>
                                            {noticia.map((e) => (
                                                <ReactPlayer style={{ zIndex: 10000 }} controls width={"100%"}  url={e.url_video} />
                                            ))}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : null}

                    {datax4.length > 0 &&
                        (<section className="section-home-clients pt-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="clients-slider-container">
                                            <Slider {...settings}>
                                                {datax4.map((e) => (
                                                    <img className="slide-img lazyload" data-src={`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_image}`} title={e.title} alt={e.alt}/>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="divider"></div>
                                    </div>
                                </div>
                            </div>
                        </section>)}
                </>}
        </div>
    )
}

export default DetalleLinea


const selectedStyles = {
    backgroundColor: "rgb(0, 61, 165)",
    borderRadius: 31.5,
    color: 'white'
}
const selectedStyles2 = {
    background: "linear-gradient(90deg, #003DA5 95%, #84BD00 5%)",
    borderRadius: 0,
    color: 'white'
}
const UnselectedStyles = {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: 31.5,
    color: '#003DA5'
}
const UnselectedStyles2 = {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: 0,
    color: '#8C8C8C'
}