import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const baseURL = `${mainUrl}/web-page-conf`

//lineas de negocio
const getAllTestimonios = async () => {
  const { data: res } = await axios.get(`${baseURL}/web/testimonials/getall`);
  return res;
};





export default {
 getAllTestimonios
}