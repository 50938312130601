/** @format */

import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Requests from "./Requests/RequestAnual";
import { Flip } from "../../components/Pdf";
import { AddHelmet } from "../../components/Helmet/AddHelmet";

function VerInforme() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL;
  let history = useHistory();
  const [bandera, setBandera] = useState(false);
  const [data, setData] = useState([]);
  const { name } = useParams();
  const [url, setUrl] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getNewById();
  }, []);

  useEffect(() => {
    if (url) {
      setBandera(true);
    }
  }, [url]);

  const getNewById = async () => {
    const json = { report_name: decodeURIComponent(name) };
    try {
      Requests.getReportByName(json)
        .then((e) => {
          setData(e);
          //setUrl(`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_report_file}`);
          getUrl(`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_report_file}`);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getUrl = async (link) => {
    const blob = await fetch(link).then((r) => r.blob());
    const urlNew = window.URL.createObjectURL(blob);
    setUrl(urlNew);
  };

  return (
    <div>
      <AddHelmet data={{ title: "Informe Anual", description: "Desc" }}></AddHelmet>

      <div className='w-full inner-page-container pt-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <Button
                className='mb-2 mt-2'
                variant='outlined'
                onClick={() => {
                  history.goBack();
                }}
                style={{ borderRadius: 20, borderColor: "rgba(0,0,0,0)" }}
                startIcon={<KeyboardBackspaceIcon />}
              >
                REGRESAR
              </Button>
            </div>
            <div className='col-md-8'>
              <h1 className='title-right mb-4 mt-2'>{data.report_name}</h1>
            </div>
          </div>
        </div>
        {/*<div>{bandera == true ? <Flip file={`${mainUrl}/azure-storage/api/blob/download/?file=${data.name_report_file}`} /> : null}</div>*/}
        {bandera && (
          <>
            <Flip file={url} />
          </>
        )}
      </div>
    </div>
  );
}

export default VerInforme;
