import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL 
const baseUrl = `${mainUrl}/web-page-conf`


  const getAllReports = async () => { 
        const {data: res} = await axios.get(`${baseUrl}/web/annual-report`);
        return res;
    };

  const getReportById = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/annual-report/${id}`);
    return res;
  };

  const getReportByName = async (desc) => { 
    const {data: res} = await axios.post(`${baseUrl}/web/annual-report/getByName/`,desc);
    return res;
  };

  export default {
    getReportById,
    getAllReports,
    getReportByName
  }