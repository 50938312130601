import React, { useState, useEffect, useRef } from 'react';
import { IconButton, } from '@mui/material';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Select2 from 'react-select'
import FormControl from '@material-ui/core/FormControl';
import Header from '../../components/header/Header'
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Requests from './Requests/RequestBlog';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useHistory } from 'react-router-dom'
import Footer from '../../components/Footer/Footer';
import imagen from './photo-2.jpg'
import ReactHtmlParser from "react-html-parser";


const imgs = [
  { image: 'https://www.transporteinformativo.com.mx/wp-content/uploads/2020/03/kw-red-1.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2020/03/red-ambienta2-1024x597.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2019/11/red-ambiental-1-1024x597.jpg' },
]

function Noticias() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [estado, setEstado] = useState('')
  const [search, setSearch] = useState('')
  const [news, setNews] = useState([])
  const [states, setStates] = useState([])
  const [bandera, setBandera] = useState(false)
  const [fullNews, setFullNews] = useState([])
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const open = Boolean(anchorEl);
  const [limit, setLimit] = useState(3)
  const [selected, setSelected] = useState(1);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const regex = /(<([^>]+)>)/ig;

  useEffect(() => {
    window.scrollTo(0, 0)
    getNews()
    getNewState()
  }, []);

  const getNewState = async () => {
    try {
      Requests.getAlllNewsStates().then((e) => {
        setStates(e)
      }).catch((error) => {
      })
    } catch (error) {
    }
  }

  const getNews = async () => {
    try {
      Requests.getAlllNews().then((e) => {
        setNews(e)
        setFullNews(e)
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const onDropDownCHange = (unidad) => {
    setEstado(unidad)
  }

  const busqueda = () => {

    //PARA QUE BUSQUE AL MOMENTO DE ESCRIBIR PONER ANTES DE .MAP(): 
    /*
    .filter((e)=>{
      if(search == ""){
        return e
      } else if(e.title_publication.toLowerCase().includes(search.toLowerCase()) ||
                e.text_publication.toLowerCase().includes(search.toLowerCase())||
                e.lst_business_unit.map((value)=>(
                value.ubication.state.toLowerCase().includes(estado.state.toLowerCase())
       ))){
        return e
      }
    })
    */
  
    const newsFiltered = fullNews.filter((e) => {
      if (search == "" && estado == '') {
        return fullNews
      }
      else if (search !== '' && estado !== '') {
        if ((e.title_publication.toLowerCase().includes(search.toLowerCase()) ||
          e.text_publication.toLowerCase().includes(search.toLowerCase())) &&
          e.lst_business_unit.map((value) => (
            value.ubication.state.toLowerCase().includes(estado.state.toLowerCase())
          ))) {
          return e
        }
      } else if (search !== '' && estado == '') {
        if (e.title_publication.toLowerCase().includes(search.toLowerCase()) ||
          e.text_publication.toLowerCase().includes(search.toLowerCase())) {
          return e
        }
      }
      else if (search == '' && estado !== '') {
        const estado2 = estado.state
        let filtro = null;
        e.lst_business_unit.map((item) => {
          if (item.ubication.state.includes(estado2)) {
            filtro = e
            return e
          }
          return e
        })
        return filtro;
      }
    })
    debugger;
    setNews(newsFiltered);
    setEstado('')
    setSearch('')
  }

  const setDataSeoStorage = (data) => {
    window.sessionStorage.setItem("seoRA", JSON.stringify(data));
}


  return (
    <div>
      <div className="w-full inner-page-container p-4 background-custom">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h1 className="mb-4">Noticias</h1>

              <TextField className="mb-2" InputProps={{ disableUnderline: true, }}
                value={search}
                type="search"
                placeholder="Buscar"
                onChange={(e) => { setSearch(e.target.value) }}
                variant="standard"
              />

              <Select2 className="mb-4" options={states} getOptionLabel={e => e.state} getOptionValue={e => e.id} value={estado} placeholder="Ubicación" onChange={onDropDownCHange} />
              <button className="button btn-action-primary mb-4"
                onClick={() => { busqueda(); setBandera(true) }} variant="contained">Buscar</button>
              {bandera &&
                <button className="button btn-action-secundary mb-4 mx-2"
                  onClick={() => { setNews(fullNews); setBandera(false) }} variant="contained">Limpiar filtros</button>
              }
            </div>

            <div className="col-md-8 offset-md-1">
              {
                news.length > 0 && (
                  news.map((item, index) => (
                    <div style={{ display: index < limit ? 'flex' : 'none' }} className="news-card mb-4">
                      <div style={{ cursor: 'pointer' }} onClick={() => {
                      setDataSeoStorage({
                          meta_description: item.meta_description,
                          meta_keywords: item.meta_keywords,
                          title_page: item.title_page,
                          url: item.url
                      });
                        history.push(`/Blog_Detalle/${encodeURIComponent(item.url)}`);
                      }} className="container-fluid g-0">
                        <div className="row g-0">
                          <div className="col-sm-4">
                            <div className="img-container lazyload" key={item.id} title={item.title} data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_cover_image}`}>
                            </div>
                          </div>
                          <div className="col-sm-8 card-block" >
                            <h5 className="news-card-title mb-4">{item.title_publication}</h5>
                            <p className="news-card-date">{item.start_date}</p>
                            <p className="news-card-descript mb-2">
                              {ReactHtmlParser(item.text_publication.replace(regex, '').substring(0, 180)) + '...'}</p>
                            <Button className="btn-action-secundary news-card-button" endIcon={<ArrowRightAltIcon />}
                              onClick={() => {
                                // history.push(`/Blog_Detalle/${item.route}`);

                              }}>Ver más
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              {/* {
                      news.length > 0 && (
                        news.map((item,index)=>(
                          <div key={index} style={{display:index < limit ? 'flex' : 'none'}} className="w-full flex-row flex my-4">
                          <div className="w-4/12">
                              <img className="h-full w-full bg-gray-300" src={item.cover_image} />
                          </div>
                          <div className="w-8/12 p-3 bg-white">
                             <div className="flex-row flex">
                             <p className="RobotoBold w-11/12" style={{fontSize:18}}>{item.title_publication}</p>
                             <p className="RobotoRegular relative bottom-2" style={{fontSize:12}}>{item.create_at_str}</p>
                             </div>
                              <p className="RobotoRegular mt-5" style={{fontSize:14, color:'rgb(140,140,140)'}}>{item.text_publication.replace(regex, '').substring(0,180)+'...'}</p>
                              <div className=" flex-row flex items-center justify-center" style={{float:'right', bottom:0}}>
                                  <Button style={{color:'rgb(0, 61, 165)',textTransform: 'lowercase'}}  endIcon={<ArrowRightAltIcon />} 
                                  onClick={()=>{
                                    history.push({
                                      pathname: '/Blog_Detalle',
                                      state: { detail: item.id }
                                  });
                                  }}>
                                  Ver más
                                </Button>
                                  </div>
                          </div>
                      </div>    
                      ))
                      )
                      
                    } */}
              {news.length > 3 && news.length > limit && (
                <div className="items-center justify-center flex">
                  <button className="button btn-action-primary mb-4" onClick={() => { setLimit(limit + 2) }}>Cargar más noticias</button>
                  {/* <input className="2 btn-action-primary" type="button" value="CARGAR MÁS NOTICIAS" className="trebuchet" style={{outline: 'none',backgroundColor:"rgb(0, 61, 165)",
                          width:'40%', borderRadius:30,paddingBlock:15, fontSize:21,color:'white',cursor:"pointer"}}  onClick={()=>{setLimit(limit+2)}}/> */}
                </div>
              )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Noticias