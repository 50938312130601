import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL;
const baseUrl = `${mainUrl}/web-page-conf`

const getSEO = async (code) => {
    const { data: res } = await axios.get(`${baseUrl}/web/section/${code}`);
    return res;
}

export default {
    getSEO,
}