/** @format */

import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { estados } from "./estados/estados";
import { ciudades } from "./ciudades/ciudades";
import Requests from "./Request/Requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsApp from "@mui/icons-material/WhatsApp";
import banner from "./back-cover-sucursal.jpg";
import { AddHelmet } from "../../components/Helmet/AddHelmet";

function SolicitaCotizacion() {
  const [active, setActive] = useState(false);
  const [errorT1, setErrorT1] = useState(false);
  const [errorT2, setErrorT2] = useState(false);
  const [errorT3, setErrorT3] = useState(false);
  const [errorT4, setErrorT4] = useState(false);
  const [errorT5, setErrorT5] = useState(false);
  const [errorT6, setErrorT6] = useState(false);
  const [username, setUsername] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [estado, setEstado] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [ciudades2, setCiudades2] = useState([]);
  const [validEmail, setValidEmail] = useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendContactInfo = async () => {
    setActive(true);
    if (username == "" || username == undefined) {
      toastErr("El campo nombre no puede ir vacío");
      setErrorT1(true);
      setActive(false);
      return;
    } else {
      setErrorT1(false);
    }
    if (telefono == "" || telefono == undefined) {
      toastErr("El campo teléfono no puede ir vacío");
      setErrorT2(true);
      setActive(false);
      return;
    } else {
      setErrorT2(false);
    }
    if (correo == "" || correo == undefined) {
      toastErr("El campo correo no puede ir vacío");
      setErrorT3(true);
      setActive(false);
      return;
    } else {
      setErrorT3(false);
    }
    if (!validEmail) {
      toastErr("El correo no es valido");
      setErrorT3(true);
      setActive(false);
      return;
    } else {
      setErrorT3(false);
    }
    if (estado == "" || estado == undefined) {
      toastErr("El campo estado no puede ir vacío");
      setErrorT4(true);
      setActive(false);
      return;
    } else {
      setErrorT4(false);
    }
    if (ciudad == "" || ciudad == undefined) {
      toastErr("El campo ciudad no puede ir vacío");
      setErrorT5(true);
      setActive(false);
      return;
    } else {
      setErrorT5(false);
    }
    if (mensaje == "" || mensaje == undefined) {
      toastErr("El campo mensaje no puede ir vacío");
      setErrorT6(true);
      setActive(false);
      return;
    } else {
      setErrorT6(false);
    }

    const state = JSON.parse(estado);
    const city = JSON.parse(ciudad);

    const info = {
      city: city.name,
      email: correo,
      message: mensaje,
      name: username,
      phone: telefono,
      state: state.name,
      business: empresa
    };
    //console.log(info)
    try {
      Requests.postCotizador(info)
        .then((res) => {
          //console.log("RES---",res)
          if (res.code == 200) {
            toastSuccess("Tu mensaje ha sido enviado, gracias.");
            setUsername("");
            setTelefono("");
            setCorreo("");
            setEstado("");
            setCiudad("");
            setMensaje("");
            setEmpresa("");
          }
          setActive(false);
        })
        .catch((err) => {
          setActive(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setActive(false);
    }
  };

  const toastErr = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const checkEmail = () => {
    if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(correo)) {
      setErrorT3(true);
      setValidEmail(false);
    } else {
      setErrorT3(false);
      setValidEmail(true);
    }
  };

  const handleChangeSelect = (event) => {
    if (event.target.value) {
      const parseado = JSON.parse(event.target.value);
      //console.log(parseado)
      setCiudad('');
      setCiudades2(ciudades.filter((item) => item.state_id == parseado.id));
      setEstado(event.target.value);
    }
  };

  const handleChangeSelect2 = (event) => {
    if (event.target.value) {
      const parseado = JSON.parse(event.target.value);
      //console.log(parseado)
      setCiudad(event.target.value);
    }
  };

  return (
    <div>
      <AddHelmet data={{ title: "Solicita tu cotización", description: "Desc" }}></AddHelmet>
      <div className='w-full'>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className='w-full inner-page-container background-custom  pb-4'>
          <div className='banner-location p-5' style={{ backgroundImage: ` url(${banner})`, backgroundSize: "cover" }}></div>
          <div className='container'>
            <div className='row mb-4 mt-n4'>
              <div className='col-sm-6 offset-sm-3 pb-12'>
                <form className='back-form-white' onsubmit='return false'>
                  <div class='container-fluid'>
                    <div class='row '>
                      <div class='col-sm-12'>
                        <h1>Solicita tu cotización</h1>
                      </div>
                      <div class='col-sm-12'>
                        <p className='mb-2'>Deja tus datos y a la brevedad posible nos estaremos contactando contigo</p>
                      </div>
                      <div class='col-sm-12'>
                        <input
                          onError={() => {
                            alert("vacio");
                          }}
                          type='text'
                          required
                          className='mt-3 form-control'
                          style={{ padding: 10, borderColor: errorT1 ? "red" : "rgb(162, 185, 226)" }}
                          placeholder='Nombre*'
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                        />
                      </div>
                      <div class='col-sm-12'>
                        <input
                          type='number'
                          onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10);
                          }}
                          required
                          maxLength={10}
                          className='mt-3 no-arrow form-control'
                          style={{ borderColor: errorT2 ? "red" : "rgb(162, 185, 226)", padding: 10 }}
                          placeholder='Teléfono*'
                          value={telefono}
                          onChange={(e) => {
                            setTelefono(e.target.value);
                          }}
                        />{" "}
                      </div>
                      <div class='col-sm-12'>
                        <input
                          required
                          type='email'
                          onBlur={() => {
                            checkEmail();
                          }}
                          className='mt-3 form-control'
                          style={{ borderColor: errorT3 ? "red" : "rgb(162, 185, 226)", padding: 10 }}
                          placeholder='Correo electrónico*'
                          value={correo}
                          onChange={(e) => {
                            setCorreo(e.target.value);
                          }}
                        />
                      </div>
                      <div class='col-sm-12'>
                        <input
                          type='text'
                          className='mt-3 form-control'
                          style={{ padding: 10, borderColor: "rgb(162, 185, 226)" }}
                          placeholder='Empresa'
                          value={empresa}
                          onChange={(e) => {
                            setEmpresa(e.target.value);
                          }}
                        />
                      </div>
                      <div class='col-sm-6'>
                        <select
                          required
                          value={estado}
                          className='mt-3 form-select'
                          style={{ borderColor: errorT4 ? "red" : "rgb(162, 185, 226)", padding: 10 }}
                          onChange={handleChangeSelect}
                        >
                          <option value={''} label='Estado*'></option>
                          {estados.map((item, index) => (
                            <option key={index} value={JSON.stringify(item)}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class='col-sm-6'>
                        <select
                          required
                          className='mt-3 form-select'
                          value={ciudad}
                          style={{ borderColor: errorT5 ? "red" : "rgb(162, 185, 226)", padding: 10 }}
                          onChange={handleChangeSelect2}
                        >
                          <option value={''} label='Ciudad*'></option>
                          {ciudades2.map((item, index) => (
                            <option key={index} value={JSON.stringify(item)}>
                              {item.name}
                            </option>
                          ))}
                        </select>{" "}
                      </div>
                      <div class='col-sm-12'>
                        <textarea
                          required
                          className='my-3 form-control'
                          style={{ borderColor: errorT6 ? "red" : "rgb(162, 185, 226)", padding: 10 }}
                          placeholder='Mensaje*'
                          onChange={(e) => {
                            setMensaje(e.target.value);
                          }}
                          value={mensaje}
                          cols='40'
                          rows='5'
                        ></textarea>
                      </div>
                      <div class='col-sm-12'>
                        <button
                          className='2'
                          type='button'
                          onClick={() => {
                            sendContactInfo();
                          }}
                          disabled={active}
                          style={{
                            outline: "none",
                            backgroundColor: "rgb(0, 61, 165)",
                            width: "25%",
                            borderRadius: 30,
                            paddingBlock: 5,
                            fontSize: 18,
                            color: "white",
                            cursor: "pointer",
                            marginBottom: "40px",
                            float: "right",
                            borderColor: "rgba(0, 61, 165, 0)"
                          }}
                        >
                          ENVIAR
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='col-sm-12'></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6 offset-sm-3'>
                <div className='back-form-white'>
                  <div class='container-fluid'>
                    <div class='row'>
                      <div class='col-sm-12 mb-4'>
                        <h2>
                          ¿Tienes más dudas? <br></br>
                          Comunícate con nosotros:
                        </h2>
                      </div>
                      <div className='col-sm-12'>
                        <h3 className=''>Medios de contacto:</h3>
                      </div>
                      <div className='col-sm-6 back-form-contact-whats'>
                        <IconButton size='large' edge='start' color='inherit' aria-label='menu'>
                          {" "}
                          <WhatsApp style={{ color: "green" }} />
                        </IconButton>
                        <a className='taga-text-decorate' href={`tel:55 49 40 53 82`}>
                          55 49 40 53 82
                        </a>
                      </div>
                      <div className='col-sm-6 back-form-contact-phone'>
                        <IconButton size='large' edge='start' color='inherit' aria-label='menu'>
                          {" "}
                          <LocalPhoneIcon />
                        </IconButton>
                        <a className='taga-text-decorate' href={`tel:800 733 22 22`}>
                          800 733 22 22
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolicitaCotizacion;
