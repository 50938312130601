// React
import React, { useState, useEffect, useRef } from 'react'

const API = 'AIzaSyDFHWDe_YROHM_P6DAJrKHAh02QDjapvo4'
const BASE_URL = '20.88.205.200'
const MAP_ID = 'b8ccaa6c71d4d7a6'
const MAP_CENTER = { lat: 23.634501, lng: -102.552784 }

const getGoogleMaps = () => new Promise((resolve) => {
  window.resolveGoogleMapsPromise = () => {
    resolve(window?.google)
    delete window.resolveGoogleMapsPromise
  };

  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?v=3&sensor=false&key=${API}&callback=resolveGoogleMapsPromise`
  script.async = true
  document.body.appendChild(script)
})

const Map = ({latSuc,longSuc}) => {
  const [bandera,setBandera] = useState(false)
  const [markersSwitch, setMarkersSwitch] = useState('lineas')
  const [sucursales, setSucursales] = useState([])

  const [map, setMap] = useState(null);
  const mapRef = useRef(null)


  const createMap = () => {
    const myLatLng = { lat: latSuc, lng: longSuc };
  const map = new window.google.maps.Map(document.getElementById("map"), {
    zoom: 15,
    center: myLatLng,
    mapId: MAP_ID,
    center: myLatLng
  });

  new window.google.maps.Marker({
    position: myLatLng,
    map,
  });

  }

  // Initialize getting Google Maps script and create map
  useEffect(() => {
    (async () => {
      await getGoogleMaps()
      createMap()
    })()
  }, [])

  // Once the map is ready get Presencia and Sucursales
  useEffect(() => {
    if (!!map) {
     // getPresencia()
     // getSucursales()
    }
  }, [map])

  return (
    <div>
      <section id='map-section' className="map-section-suc">
        <div className="map-container-v4-suc" id="map" ref={mapRef}></div>
      </section>
    </div>
  );
}

export default Map;