import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL;
 const baseUrl = `${mainUrl}/web-page-conf/web/web-page-config/`
 const baseUrl2 = `${mainUrl}/web-page-conf/`

const getAllEmployees = async () => { 
    const {data: res} = await axios.get(`${baseUrl}carousel`);
    return res;
  };

  const getAllEmployees2 = async () => { 
    const {data: res} = await axios.get(`${baseUrl2}section`);
    return res;
  };

  const getIndicators = async () => { 
    const {data: res} = await axios.get(`${baseUrl2}web/indicator/getall`);
    return res;
  };

  export default {
    getAllEmployees,
    getAllEmployees2,
    getIndicators
    }