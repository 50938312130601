import React, { useState, useEffect, useRef } from 'react';
import { IconButton, } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';
import EstructuraRequests from './Requests/EstructuraRequests'
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from "react-html-parser";
import { useHistory, useParams } from 'react-router-dom'
import ReactPlayer from 'react-player';
import { AddHelmet } from '../../../components/Helmet/AddHelmet';


function RespSocialEstructura() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  let history = useHistory()
  const { name } = useParams();
  const [fotos, setFotos] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [data, setData] = useState([])
  const [noVideos, setNoVideos] = useState(false)
  const [dataSeo, setDataSeo] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
    getInfoData()
  }, [name]);

  const getInfoData = () => {
    try {
      EstructuraRequests.getAllInformationInst().then((res) => {
        if (res.description === '0' || res.description === '<p>0</p>') {
          history.push('/');
        }
        setData(res);
        setDataSeoStorage({
          meta_description: res.meta_description,
          meta_keywords: res.meta_keywords,
          title_page: res.title_page,
          url: res.url
        });
        if (res.photos) {
          setFotos(res.photos);
        }

        if(res.videos.length > 0){
          setNoVideos(true)
      }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setDataSeoStorage = (data) => {
    setDataSeo(data);
    window.sessionStorage.setItem("seoRA", JSON.stringify(data));
  }

  return (
    <div>
      <AddHelmet data={dataSeo}></AddHelmet>
      <div className="w-full inner-page-container pb-12">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {data !== null || data !== undefined ?
                <>
                  <Carousel
                    showArrows={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles,
                          left: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowBackIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    renderArrowNext={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles2,
                          right: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    showIndicators={fotos.length > 1 ? true : false}
                    renderIndicator={(onClickHandler, isSelected, label) => (
                      <div className="inline-block items-center justify-center ml-8">
                        <ul
                          style={{ ...indicatorStyles, opacity: isSelected ? '1' : '0.7' }}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}>
                          <li style={{ color: isSelected ? "rgb(0, 61, 165)" : "white", fontSize: 50 }}></li>
                        </ul>
                      </div>

                    )}
                    autoPlay
                    interval={3000}
                    infiniteLoop
                    swipeable
                    //  renderArrowNext={arrowNext}
                    emulateTouch
                    showThumbs={false}
                    showStatus={false}>
                    {/* data.image */}
                    {fotos.map((data, index) => (
                      <div className="inner-section-carousel" style={{ backgroundImage: ` url(${mainUrl}/azure-storage/api/blob/download/?file=${data.name_image})`, }} title={data.title}>

                      </div>
                    ))}
                  </Carousel>
                  <br></br>
                  <div>
                    <p className="my-10 rich-text-editor">
                      {ReactHtmlParser(data !== null ? data.description : null)}
                    </p>
                  </div>
                  {noVideos == true ? 
                  <section className="service-inner-video mt-10" >
                  <div className="container">
                      <div className="row">
                          <div className="col-sm-12">
                              <Carousel
                              showArrows
                              autoPlay
                              interval={3000}
                              infiniteLoop
                              swipeable
                              emulateTouch
                              showThumbs={false}
                              showStatus={false}>
                                  {data.videos.map((e)=>(
                                      <ReactPlayer style={{zIndex:10000}} controls width={"100%"} height={"80vh"} url={e.url_video}/>
                                  ))}
                                  </Carousel>
                          </div>
                      </div>
                  </div>
              </section>
                :null}
                </> :
                <CircularProgress />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const indicatorStyles = {
  display: 'inline-block',
  color: '#fff',
  cursor: 'pointer',
};

const selectedStyles = {
  backgroundColor: "rgb(0, 61, 165)",
  borderRadius: 31.5,
  color: 'white'
}

const arrowStyles = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};

const arrowStyles2 = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};

const unselectedStyles = {
  color: 'black'
}

export default RespSocialEstructura