import axios from 'axios';

  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const url = `${mainUrl}/web-page-conf/`
  const baseUrl = 'http://20.88.217.108/inf-institutional'

  const getInfoInst = async () => { 
      const {data: res} = await axios.get(`${baseUrl}/admin/catalog/information-institutional`);
      return res;
    };

  const getAllSections = async () => { 
    const {data: res} = await axios.get(`${url}web/section`);
    return res;
  };
  const getAllSocial = async () => { 
    const {data: res} = await axios.get(`${url}web/social-networks/getall`);
    return res;
  };

  const getAllInformationInst = async () => { 
    const {data: res} = await axios.get(`${url}web/social-responsability/getStrategyContent`);
    return res;
  };

  export default {getAllSections, getInfoInst, getAllSocial, getAllInformationInst}