import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import lnegocioRequest from "./request/lnegocioRequest";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from 'react-router-dom';
import LazyHydrate from "react-lazy-hydration";
// import LazyLoad from 'react-lazy-load';

const Lnegocio = () => {

  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const history = useHistory();
  const [images, setImages] = useState([]);
  const regex = /(<([^>]+)>)/ig;

  useEffect(() => {
    try {
      lnegocioRequest
        .getAllBusinessLine()
        .then((e) => {
          //  console.log("RES", e);
          setImages(e);
          var container = document.getElementById("parrafo2");
          var containerHeight = container.clientHeight;
          var para = container.children[0];


        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClick = (id) => {
    history.push(`/detalle_linea/${id}`);
  };

  const textCut = (str) => {
    var yourString = str; //replace with your string.
    var maxLength = 200 // maximum number of characters to extract

    //trim the string to the maximum length
    var trimmedString = yourString.substring(0, maxLength);

    //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    return trimmedString + '...'
  }

  const handleInputChange = () => {
    const section = document.querySelector( '#indicadores' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    setTimeout(()  => {
      const section2 = document.querySelector( '#titlekey' );
      section2.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }, 100);   
  }

  return (
    <LazyHydrate whenVisible>
      <Carousel
        showArrows
        //  autoPlay
        //  interval={3000}
        //  infiniteLoop
        //  swipeable
        //  emulateTouch
        showThumbs={false}
        showStatus={false} 
        // onChange={handleInputChange}        
        >
        {images.map((data, index) => (
          // <LazyLoad offset={0}>
            <div id="titlekey" key={`clientes${data.id}`} title={data.title} className="slider-businessLines-main lazyload" data-size="cover" data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${data.name_cover_image.replace(" ", "%20")}` }>
              <div className="container z-10">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="slider-bl-block-title">
                      <h2  className="slider-bl-subtitle mb-2">Conoce como podemos ayudarte</h2>
                      <h3 className="slider-bl-title">{data.name}</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="container-businessLine">
                      <p id="parrafo2" className="parrafo">
                        {ReactHtmlParser(data.description)}
                      </p>
                      <p className="ellipsis">...</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="container-businessLine">
                      <p id="parrafo2" className="parrafo">
                        {ReactHtmlParser(data.advantage)}
                      </p>
                      <p className="ellipsis">...</p>
                    </div>
                  </div>
                  <div className="col-sm-12 text-center">
                    <a href="javascript:;" className="button-seemore" onClick={() => { handleClick(data.url) }}>Ver más</a>
                  </div>

                </div>

              </div>
            </div>
          // </LazyLoad>
        ))}
      </Carousel>
    </LazyHydrate>

  );
}

export default Lnegocio;
