import React from 'react'
import Button from "@mui/material/Button";
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import "./footer.css"
function Footer() {
    const theme = useTheme();
    let history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <p className="">© 2021 Red Ambiental</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
