import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
 const baseUrl = `${mainUrl}/web-page-conf`


  const getZona = async (id) => { 
        const {data: res} = await axios.get(`${baseUrl}/web/business-unit/${id}/zones`);
        return res;
    };

    const getByName = async (desc) => { 
      const {data: res} = await axios.post(`${baseUrl}/web/business-unit/getByName/`,desc);
      return res;
    };

  export default {
    getZona,
    getByName
  }