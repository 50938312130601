import React, { useState, useEffect, useRef } from 'react';
import { IconButton, } from '@mui/material';
import Button from '@mui/material/Button';
import ReactPlayer from 'react-player'
import Header from '../../components/header/Header'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Requests from './Requests/RequestBlog';
import { Carousel } from 'react-responsive-carousel';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from "react-html-parser";
import Footer from '../../components/Footer/Footer';
import { AddHelmet } from '../../components/Helmet/AddHelmet';

const options = [
  'Historia',
  'Mision',
  'Vision',
  'Valores',
];

const imgs = [
  { image: 'https://www.transporteinformativo.com.mx/wp-content/uploads/2020/03/kw-red-1.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2020/03/red-ambienta2-1024x597.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2019/11/red-ambiental-1-1024x597.jpg' },
]

function Blog() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const { name } = useParams();
  let history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState(1);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [noticia, setNoticia] = useState(null)
  const [fotos, setFotos] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    getNewById()
  }, [name]);

  const getNewById = async () => {
    const json = { description: decodeURIComponent(name) }
    try {
      Requests.getNewByDesc(json).then((e) => {
        //  console.log("NEWS---",e)
        setNoticia(e)
        setFotos(e.lstblog_photos)
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <AddHelmet data={{ title: 'Detalle noticia', description: 'Desc' }}></AddHelmet>
      {/* <Button style={{textTransform:'none'}} className="button-newRequest" onClick={()=>{history.push('/solicita_cotizacion')}}>Solicita tu cotización</Button> */}
      {/* <Header/> */}
      <div className="w-full inner-page-container p-4 background-custom">
        <div className="container pb-2">
          <div className="row">
            <div className="col-sm-12">
              {noticia !== null || noticia !== undefined ?
                <>
                  <Carousel
                    showArrows={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles,
                          left: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowBackIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    renderArrowNext={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles2,
                          right: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    showIndicators={true}
                    renderIndicator={(onClickHandler, isSelected, label) => (
                      <div className=" inline-block items-center justify-center ml-8">
                        <ul
                          style={{ ...indicatorStyles, opacity: isSelected ? '1' : '0.7' }}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}>
                          <li style={{ color: isSelected ? "rgb(0, 61, 165)" : "white", fontSize: 50 }}></li>
                        </ul>
                      </div>

                    )}
                    autoPlay
                    interval={3000}
                    infiniteLoop
                    swipeable
                    //  renderArrowNext={arrowNext}
                    emulateTouch
                    showThumbs={false}
                    showStatus={false}>
                    {fotos.map((data, index) => (
                      <div key={index} className="inner-section-carousel" style={{ backgroundImage: ` url(${mainUrl}/azure-storage/api/blob/download/?file=${data.name_photo})`, }} title={data.title}>

                      </div>
                    ))}
                  </Carousel>
                  <div className="w-100 d-flex" style={{ flexDirection: 'column' }}>
                    <div className="my-4 w-100 lg:px-0">
                      <h1 className="">{noticia !== null ? noticia.title_publication : null}</h1>
                    </div>
                    <div className="row align-items-center w-100 d-lex"
                      style={{ borderBottomWidth: 40, borderColor: "rgb(151, 151, 151)", justifyContent: 'space-between', }}>
                      <div className="">
                        <h2 className="">{noticia !== null ? noticia.start_date : null}</h2>
                      </div>
                    </div>
                    <div className="py-4 flex  justify-center flex-col items-center">
                      <div className="w-full">
                        <p className='rich-text-editor'>
                          {ReactHtmlParser(noticia !== null ? noticia.text_publication : null)}
                        </p>
                      </div>
                      {noticia !== null ? (noticia.video_url !== null && noticia.video_url !== '') ?
                        <div className="my-2 blog-video-player">
                          <ReactPlayer controls width={"100%"} height={"100%"} url={noticia !== null ? noticia.video_url : null} />
                        </div> : null : null}
                    </div>
                    <div className="py-2" style={{ borderColor: '#979797', borderTopWidth: 3 }}>
                      <h6 onClick={() => { history.push('/blog') }} style={{ textAlign: 'end', color: '#003DA5', cursor: 'pointer' }}>Ver todas las noticias</h6>
                    </div>
                  </div>

                </> :
                <CircularProgress onClick={() => { console.log(noticia) }} />}
            </div>
          </div>
        </div>
        <div className="flex-row w-full flex" style={{ borderColor: "rgb(151, 151, 151)" }}>
          <div className="w-2/12" style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `linear-gradient(rgba(0, 61, 165,.5) 0%, rgba(0, 61, 165,.5) 90%), url(${imgs[1].image})` }}>
          </div>
          <div className="w-2/12" style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `linear-gradient(rgba(0, 61, 165,.5) 0%, rgba(0, 61, 165,.5) 90%), url(${imgs[2].image})` }}>

          </div>
        </div>
      </div>
    </div>
  )
}

const indicatorStyles = {
  display: 'inline-block',
  color: '#fff',
  cursor: 'pointer',
};

const arrowStyles = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};

const arrowStyles2 = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};

export default Blog