import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;
const BusinessUrl = `${baseUrl}/web-page-conf/web/privacyNotice/getall`



const getAll = async () => {
  const { data: res } = await axios.get(`${BusinessUrl}`);
  return res;
};

const getById = async(id)=>{
  const { data: res } = await axios.get(`${BusinessUrl}/${id}`);
  return res;
}


export default {
  getAll, getById
}