import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import Requests from './Requests/Requests'
import ReactHtmlParser from "react-html-parser";
import CircularProgress from '@mui/material/CircularProgress';

function Testimonios() {
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    const [testimonios, setTestimonios] = useState([])
    const regex = /(<([^>]+)>)/ig;
    const [load, setLoad] = useState(true)

    var settingsTestimonials = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    useEffect(() => {
        getTestimonios()
    }, []);

    const getTestimonios = async () => {
        try {
            Requests.getAllTestimonios().then((e) => {
                // console.log("TESTIMONIOS---",e)
                setTestimonios(e)
                setLoad(false)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <section className="section-home-testimonials background-clients">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5 testin-bl-block-title">
                        <h2 className="heading-title mb-5">Nuestros clientes comparten su experiencia</h2>
                    </div>
                    <div className="col-sm-6 offset-sm-1">
                        {testimonios.length > 0 && !load ?
                            <div className="testimonials-slider-container">
                                <Slider {...settingsTestimonials}>
                                    {testimonios.map((item, index) => (
                                        <div key={index} className="testimonials-slide">
                                            {(item.name_image_url !== null && item.name_image_url !== undefined && item.name_image_url !== '') &&
                                                <div className="img-slider mb-3 lazyload" title={item.title} data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_image_url}`}></div>}
                                            {ReactHtmlParser(item.testimony)}
                                            <h3 className="mb-3 testing-title">{item.name}<br /> <p>{item.position}</p></h3>
                                            <h4 className='testing-subtitle'>{item.client.name}</h4>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            :
                            <CircularProgress onClick={() => { console.log(testimonios) }} />
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonios
