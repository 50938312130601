import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Requests from './Request/Requests';
import ReactHtmlParser from "react-html-parser";
import { AddHelmet } from '../Helmet/AddHelmet';

function Aviso() {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const [data, setData] = useState([])
  const [datax, setDatax] = useState([]);
  const [dataSeo, setDataSeo] = useState({});

  const getAllNotice = async () => {
    try {
      Requests.getAll().then((e) => {
        setData(e)
        setDatax(e[0])
        e.map((i) => {
          setSelectedIndex(e[0].id);
          setDataSeoStorage(e[0]);
          // console.log(i)
        })
      }).catch((error) => {
        //console.log(error)
      })
    } catch (error) {
      //console.log(error)
    }
  }

  const getById = async (a) => {
    try {
      Requests.getById(a).then((e) => {
        setDatax(e)
      }).catch((error) => {
        //console.log(error)
      })
    } catch (error) {
      //console.log(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getAllNotice();
  }, []);

  const setDataSeoStorage = (data) => {
    setDataSeo(data);
    window.sessionStorage.setItem("seoRA", JSON.stringify(data));
  }

  return (
    <div>
      <AddHelmet data={dataSeo}></AddHelmet>
      <div className="w-full inner-page-container py-12 background-custom">
        <div className="container">
          <div className='row'>
            <div className='col-sm-3 mt-4'>
              <h1>Avisos de privacidad</h1>
              {data.map((item) => (
                <List key={item.id} className="bg-white">
                  <ListItem button style={selectedIndex == item.id ? selectedStyles : UnselectedStyles}
                    selected={selectedIndex === item.id}
                    onClick={(event) => { 
                    setDataSeoStorage({
                      meta_description: item.meta_description,
                      meta_keywords: item.meta_keywords,
                      title_page: item.title_page,
                      url: item.url
                    });
                    getById(item.id); handleListItemClick(event, item.id); }}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                </List>
              ))}
            </div>
            <div className='col-sm-8 offset-sm-1 py-4 notice-description'>
              <div>
                {ReactHtmlParser(datax.description)}
              </div>
            </div>
            <br />
            <br />
          </div>

        </div>
      </div>
    </div>
  )
}

export default Aviso

const selectedStyles = {
  background: "linear-gradient(90deg, #003DA5 95%, #84BD00 5%)",
  borderRadius: 0,
  color: 'white'
}

const UnselectedStyles = {
  backgroundColor: "rgba(0,0,0,0)",
  borderRadius: 0,
  color: '#8C8C8C'
}