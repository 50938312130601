import React, { useEffect, Suspense, lazy } from 'react';
import Carrusel from './carrusel/Carrusel';
import { AddHelmet } from './Helmet/AddHelmet';
import Lnegocio from './lnegocio/lnegocio'
import Map from './map/map'
import Clientes from './clientes/Clientes'
import Testimonios from './testimonios/Testimonios'
import Faqs from './faqs/Faqs'
import PressRoom from './pressRoom/PressRoom'
import { css } from "@emotion/react";
import LazyHydrate from "react-lazy-hydration";
import { CircularProgress, LinearProgress } from '@material-ui/core';
import Request from './RequestCommon';

// const LNegocioLazyComponent = React.lazy(() => import('./lnegocio/lnegocio'));
// const ClientesLazyComponent = React.lazy(() => import('./clientes/Clientes'));
// const TestimonioLazyComponent = React.lazy(() => import('./testimonios/Testimonios'));
// const MapLazyComponent = React.lazy(() => import('./map/map'));
// const FaqsLazyComponent = React.lazy(() => import('./faqs/Faqs'));
// const PressRoomLLazyComponent = React.lazy(() => import('./pressRoom/PressRoom'));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #003da5;
  background-color: red;
`;

function Main() {

    useEffect(() => {
        if (window.location.hash == '#map-section') {
            window.location.href = "#map-section"
        }
    }, [window.location.hash]);

    useEffect(() => {
        Request.getSEO('I').then((resp) => {
            console.log(resp);
        }).catch(() => {

        });
    }, []);

    return (
        <div>
            <LazyHydrate whenVisible>
                <Carrusel />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <Lnegocio />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <Clientes />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <Testimonios />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <Map />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <Faqs />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <PressRoom />
            </LazyHydrate>
            {/* <Suspense fallback={<LinearProgress />}>
                    <LazyHydrate whenVisible>
                        <LNegocioLazyComponent />
                    </LazyHydrate>
                    <LazyHydrate whenVisible>
                        <ClientesLazyComponent />
                    </LazyHydrate>
                    <LazyHydrate whenVisible>
                        <TestimonioLazyComponent />  
                    </LazyHydrate>
                    <LazyHydrate whenVisible>
                        <MapLazyComponent />      
                    </LazyHydrate>
                    <LazyHydrate whenVisible>
                        <FaqsLazyComponent />
                    </LazyHydrate>
                    <LazyHydrate whenVisible>
                        <PressRoomLLazyComponent />       
                    </LazyHydrate>
            </Suspense> */}
        </div>
    )
}

export default Main;
