import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const baseUrl = `${mainUrl}/web-page-conf/web`

export const getFaqs = async () => { 
    const {data: res} = await axios.get(`${baseUrl}/frequent-questions`);
    return res;
};

export const getFaqs2 = async (sieze) => { 
    const {data: res} = await axios.get(`${baseUrl}/frequent-questions/size-category/6`);
    return res;
};

