import axios from 'axios';
import { getBase64Ext } from '../Utils/zip';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const baseUrl = `${mainUrl}/web-page-conf`

export const getAll = async (page, size) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/press/getall/${page}/${size}`);
    return res;
};

export const getById = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/press/getById/${id}`);
    return res;
};

export const getByTitle = async (title) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/press/getByTitle/${title}`);
    return res;
};

export const getallImages = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/press/getallImages/${id}`);
    return res;
};

export const getallFiles = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/press/getallFiles/${id}`);
    return res;
};
