import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import Requests from './Requests/RequestSearch';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useHistory, useLocation } from 'react-router-dom'
import ReactHtmlParser from "react-html-parser";
import { getModules } from '../../utils/search';

const imgs = [
  { image: 'https://www.transporteinformativo.com.mx/wp-content/uploads/2020/03/kw-red-1.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2020/03/red-ambienta2-1024x597.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2019/11/red-ambiental-1-1024x597.jpg' },
]

function Search() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const history = useHistory();
  const location = useLocation();
  const [text, setText] = useState('')
  const [items, setItems] = useState([])
  const [limit, setLimit] = useState(3)
  const regex = /(<([^>]+)>)/ig;

  const busqueda = async (search) => {
    const resp = await Requests.getAll(search);
    setItems(resp);
  }

  const handleClick = ({ id_table, modulo, titulo }) => {
    const url = getModules(modulo);
    history.push({
      pathname: url.includes("id") ? url.replace("id", id_table) : url.includes("name") ? url.replace("name", encodeURIComponent(titulo)) : url,
      state: { detail: id_table }
    });
  };

  useEffect(() => {
    const s = location?.search?.split('=')[1];
    setText(s);
    busqueda(s);
  }, [location?.search]);

  return (
    <div>
      <div className="w-full inner-page-container p-4 background-custom">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h1 className="mb-4">Búsqueda de "{text}"</h1>

              <TextField className="mb-2" InputProps={{ disableUnderline: true, }}
                value={text}
                type="search"
                placeholder="Buscar"
                onChange={(e) => { setText(e.target.value) }}
                variant="standard"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    busqueda(e.target.value);
                  }
                }}
              />

              <button className="button btn-action-primary mb-4"
                onClick={() => { busqueda(text) }} variant="contained">Buscar</button>
            </div>

            <div className="col-md-8 offset-md-1">
              {
                items.length > 0 && (
                  items.map((item, index) => (
                    <div style={{ display: index < limit ? 'flex' : 'none', cursor: 'pointer' }} onClick={() => handleClick(item)} className="news-card mb-4" key={index}>
                      <div className="container-fluid g-0">
                        <div className="row g-0">
                          <div className="col-sm-4">
                            <div className="img-container" style={{ backgroundImage: `url(${mainUrl}/azure-storage/api/blob/download/?file=${item?.imagen?.replace(/\s/g, "%20")})`, }}>
                            </div>
                          </div>
                          <div className="col-sm-8 card-block" style={{ cursor: 'pointer' }}
                          >
                            <h5 className="news-card-title mb-4">{item.titulo}</h5>
                            <p className="news-card-date">{item.fecha}</p>
                            <p className="news-card-descript mb-2">
                              {ReactHtmlParser(item.descripcion?.replace(regex, '').substring(0, 180)) + '...'}</p>
                            <Button className="btn-action-secundary news-card-button" endIcon={<ArrowRightAltIcon />}
                            >
                              Ver más
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              {items.length > 3 && items.length > limit && (
                <div className="items-center justify-center flex">
                  <button className="button btn-action-primary mb-4" onClick={() => { setLimit(limit + 2) }}>Cargar más resultados</button>
                </div>
              )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search