// React
import React, { useState, useEffect, useRef } from 'react'

// External Dependencies
import MapaRequest from './../Mapa/Requests/MapaRequest'
import iconMarker from './../Mapa/images/icon_bl_ind_com.png'

// Libraries
import { MarkerClusterer } from "@googlemaps/markerclusterer";


//const API = 'AIzaSyDFHWDe_YROHM_P6DAJrKHAh02QDjapvo4'
const API = 'AIzaSyCgkPz6gI7M1wxXMQnBQqf_Wny6QCbrWBM'
const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const BASE_URL = mainUrl
const MAP_ID = 'b8ccaa6c71d4d7a6'
const MAP_CENTER = { lat: 23.634501, lng: -102.552784 }

const getGoogleMaps = () => new Promise((resolve) => {
  window.resolveGoogleMapsPromise = () => {
    resolve(window?.google)
    delete window.resolveGoogleMapsPromise
  };

  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?v=3&sensor=false&key=${API}&callback=resolveGoogleMapsPromise`
  script.async = true
  document.body.appendChild(script)
})

const Map = (props) => {
  const [markersSwitch, setMarkersSwitch] = useState('lineas')
  const [sucursales, setSucursales] = useState([])
  const [presencia, setPresencia] = useState([])


  const [checks, setChecks] = useState([]);

  const [map, setMap] = useState(null);
  const mapRef = useRef(null)

  const [markers, setMarkers] = useState([]);
  const [clusterMarkers, setClusterMarkers] = useState([]);
  const [cluster, setCluster] = useState(null)

  const createMap = () => {
    // Create a map only if the reference exists
    if (mapRef?.current) {
      setMap(new window.google.maps.Map(mapRef?.current, {
        zoom: 5,
        mapId: MAP_ID,
        center: MAP_CENTER
      }))
    }
  }

  const addNewMarkers = (newMarkers, options) => {
    // Add Markers in Map
    const markersResult = markers.concat(newMarkers.map(({ type, position, icon, content }) => {
      // Create Marker
      const marker = new window.google.maps.Marker({
        title: type,
        type, position, icon, map,
        animation: window.google.maps.Animation.DROP,
        infowindow: options?.infowindow && content ? new window.google.maps.InfoWindow() : null
      });

      if (marker.infowindow) {
        // Set InfoWindow
        marker.infowindow.setContent(content)
      }

      if (options.hidden) {
        marker.setVisible(false)
      }

      // Click event on each marker
      marker.addListener('click', () => {
        if (options.bounce) {
          // Toggle bounce on each click
          marker.setAnimation(marker.getAnimation() !== null ? null : window.google.maps.Animation.BOUNCE);
          // console.log({ marker })
        }

        if (marker.infowindow) {
          // Close all infowindows
          markersResult.forEach(({ infowindow }) => {
            infowindow && infowindow.close()
          })
          // Open InfoWindow
          marker.infowindow.open({ map, anchor: marker, shouldFocus: false });
        }
      })

      return marker
    }))

    if (options.cluster) {
      setClusterMarkers(markersResult)
    } else {
      setMarkers(markersResult)
    }
    return markersResult
  };

  const getSucursales = async () => {
    try {
      const result = await MapaRequest.getAllBusinessLine2()
      setSucursales(result)
      // console.log({sucursales: result})

      // Create array of new markers, because we need to extract these from business_unit not from the result
      const newMarkers = []
      result.forEach(({ name: type, business_unit }) => {
        // For each business unit
        business_unit.forEach(({ latitude, longitude, name, street, ext_number, sector, postal_code, ubication: location, phone_number }) => {
          // Create an object with marker information, not marker yet.
          newMarkers.push({
            position: new window.google.maps.LatLng(latitude, longitude),
            icon: iconMarker,
            type,
            visible: true,
            content: `
              <div id="content">
                <h3 id="firstHeading" className="firstHeading">${name}</h3>
                <div id="bodyContent">
                  <p>${street} ${ext_number},<br/>Col. ${sector} C.P. ${postal_code}, <br/>${location?.state}, México <br>Tel. ${phone_number}</p>
                </div>
              </div>
            `
          })
        })
      })
      // Add Markers to map with infowindow and hidden
      addNewMarkers(newMarkers, { infowindow: true, hidden: true });

      // console.log({ newMarkers })
    } catch (error) {
      console.log(error)
    }
  }

  const getPresencia = async () => {
    try {
      const result = await MapaRequest.getPresence()
      setPresencia(result)
      // console.log({presencia: result})

      // Create a dictionary of types of markers
      let markerTypes = {}

      // Create new markers based on the result from backend
      const newMarkers = result.map(({ latitude, longitude, business_line }) => ({
        latitude, longitude,
        marker: `${BASE_URL}/azure-storage/api/blob/download/?file=${business_line?.name_marker_image}`,
        type: business_line.name
      }))
        .map(({ latitude, longitude, marker: icon, type }) => {
          // Add new marker type or check, if repeats not a problem
          markerTypes[type] = {
            name: type,
            icon,
            enabled: true
          }
          // Returns marker info, not Marker yet.
          return {
            position: new window.google.maps.LatLng(latitude, longitude),
            icon,
            type,
            visible: true
          }
        })

      // Set types of markers (Líneas)
      setChecks(markerTypes)

      // Add markers to map
      addNewMarkers(newMarkers, { bounce: true, cluster: true })
    } catch (error) {
      console.log(error)
    }
  }

  const onMarkersSwitch = (newOption) => {
    setMarkersSwitch(newOption)

    if (newOption === 'lineas') {
      const visibleChecks = Object.values(checks).filter(({ enabled }) => enabled).map(({ name }) => name)

      markers.forEach((marker) => {
        marker.setVisible(false)
      })

      clusterMarkers
        .filter(({ type }) => visibleChecks.includes(type))
        .forEach((marker) => {
          marker.setVisible(true)
        })

      if (clusterMarkers.filter(({ visible }) => visible).length) {
        setCluster(new MarkerClusterer({ map, markers: clusterMarkers.filter(({ visible }) => visible) }))
      }

    } else if (newOption === 'suc') {
      clusterMarkers.forEach((marker) => {
        marker.setVisible(false)
      })
      cluster.setMap(null)
      markers.forEach((marker) => {
        marker.setVisible(true)
      })
    }

  }

  // Initialize getting Google Maps script and create map
  useEffect(() => {
    (async () => {
      if (window.location.hash == '#map-section') {
        setTimeout(() => {
          window.location.href = "#map-section"
        }, 1000);
      }
      await getGoogleMaps()
      createMap()
    })()
  }, [])

  // Once the map is ready get Presencia and Sucursales
  useEffect(() => {
    if (!!map) {
      getPresencia()
      getSucursales()
    }
  }, [map])

  // If the clusterMarkers are ready create the cluster, we know the map is ready at this point
  useEffect(() => {
    if (clusterMarkers.length) {
      setCluster(new MarkerClusterer({ map, markers: clusterMarkers }))
    }
  }, [clusterMarkers])

  return (
    <div>
      <section id='map-section' className="map-section">
        <div className="map-container-v4" id="map" ref={mapRef}></div>
        <div className="controls">
          <div className="titles">
            <label for="presenciaPais">
              <input id="presenciaPais" value="lineas" onChange={() => onMarkersSwitch('lineas')} checked={markersSwitch === 'lineas'} type="radio" />        
            </label>
            <h4 className="mb-2">Nuestra presencia en el país</h4>
          </div>
          <ul className="mb-2">
            {Object.values(checks).map(({ name, enabled, icon }, key) => (
              <li key={key}>
                <label>
                  <input
                    disabled={markersSwitch !== 'lineas'}
                    type="checkbox"
                    checked={enabled}
                    onChange={() => {
                      clusterMarkers.filter(({ type }) => type === name).forEach((marker) => {
                        marker.setVisible(!enabled)
                      })

                      cluster.setMap(null)
                      if (clusterMarkers.filter(({ visible }) => visible).length) {
                        setCluster(new MarkerClusterer({ map, markers: clusterMarkers.filter(({ visible }) => visible) }))
                      }

                      checks[name].enabled = !enabled
                      setChecks({ ...checks })
                    }}
                  />
                  <figure>
                    <img src={icon} alt="Nuestra presencia en el país" />
                  </figure>
                  <span>{name}</span>
                </label>
              </li>
            ))}
          </ul>
          <div className="titles mt-4">
            <label for="sucursalCheck">
              <input id="sucursalCheck" value="suc" onChange={() => onMarkersSwitch('suc')} checked={markersSwitch === 'suc'} type="radio" />            
            </label>
            <h4>Ver sucursales</h4>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Map;