import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
 const baseUrl = `${mainUrl}/web-page-conf`


  const getAlllNews = async () => { 
        const {data: res} = await axios.get(`${baseUrl}/web/blog/getall`);
        return res;
    };

  const getAlllNewsStates = async () => { 
      const {data: res} = await axios.get(`${baseUrl}/web/blog/getAllStates`);
      return res;
  };

  const getNewID = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/blog/getall/${id}`);
    return res;
  };

  const getNewByDesc = async (desc) => { 
    const {data: res} = await axios.post(`${baseUrl}/web/blog/getbydesc/`,desc);
    return res;
  };
 
  export default {
    getNewID,
    getAlllNews,
    getAlllNewsStates,
    getNewByDesc
  }