/** @format */

import React, { useState, useEffect, useRef } from "react";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Requests from "./Requests/RequestAnual";
import { saveAs } from "file-saver";
import { AddHelmet } from "../../components/Helmet/AddHelmet";

function Informeanual() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL;
  const [data, setData] = useState([]);

  const getAllReports = async () => {
    try {
      Requests.getAllReports()
        .then((e) => {
          setData(e);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllReports();
  }, []);

  let history = useHistory();

  const saveFile = (e) => {
    saveAs(e);
  };

  const setDataSeoStorage = (data) => {
    window.sessionStorage.setItem("seoRA", JSON.stringify(data));
  };

  const viewReport = (e) => {
    history.push({
      pathname: `/verReporte_anual/${encodeURIComponent(e.url)}`,
      state: { detail: e.id }
    });
    setDataSeoStorage({
      meta_description: e.meta_description,
      meta_keywords: e.meta_keywords,
      title_page: e.title_page,
      url: e.url
    });
  };

  return (
    <div>
      <div className='w-full inner-page-container p-4 background-custom'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 mb-4'>
              <h1>Informes Anuales</h1>
            </div>
          </div>
          <div className='row'>
            {data.map((e) => (
              <div className='col-sm-12 col-md-12 col-lg-6 mb-4 '>
                <div className='news-card mb-4' key={e.id}>
                  <div className='container-fluid g-0'>
                    <div className='row g-0'>
                      <div className='col-sm-4'>
                        <div
                          className='img-container lazyload'
                          title={e.title}
                          data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_cover_image}`}
                        ></div>
                      </div>
                      <div className='col-sm-8 card-block info-anual-min-h'>
                        <h2 className='mb-3'>{e.report_name}</h2>
                        <Button
                          className='btn-action-primary news-card-button-left pointer-cursor-gral'
                          variant='contained'
                          onClick={() => {
                            viewReport(e);
                          }}
                          startIcon={<ImportContactsIcon />}
                        >
                          Ver Informe
                        </Button>
                        <Button
                          className='btn-action-primary news-card-button-left pointer-cursor-gral'
                          variant='contained'
                          onClick={() => {
                            saveFile(`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_report_file}`);
                          }}
                          startIcon={<PictureAsPdfIcon />}
                        >
                          Descargar pdf
                        </Button>
                        <p>{e.start_date_str}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Informeanual;
