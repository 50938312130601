import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
 const baseUrl = `${mainUrl}/web-page-conf`


  const getAllResp = async () => { 
        const {data: res} = await axios.get(`${baseUrl}/web/social-responsability/getall`,
        {headers: {'Accept-Encoding': 'gzip, deflate, br'}})
        return res;
    };

  const getRespById = async (id) => { 
    const {data: res} = await axios.get(`${baseUrl}/web/social-responsability/${id}`);
    return res;
  };

  const getMainContent = async () => { 
    const {data: res} = await axios.get(`${baseUrl}/web/social-responsability/getContentSocialResponsibility`);
    return res;
};

  export default {
    getRespById,
    getAllResp,
    getMainContent
  }