import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const url = `${mainUrl}/web-page-conf`

  const getAllBusinessLine = async () => { 
    const {data: res} = await axios.get(`${url}/web/business-line/quotes`);
    return res;
  };

  const getBattery = async (id) => { 
    const {data: res} = await axios.get(`${url}/web/battery/business-line/${id}`);
    return res;
  };

  const getCobertura = async (id) => { 
    const {data: res} = await axios.get(`${url}/web/business-unit/city/${id}`);
    return res;
  };

  const getCobertura2 = async (id,idBusiness) => { 
    const {data: res} = await axios.get(`${url}/web/business-unit/city/${id}/business-line/${idBusiness}`);
    return res;
  };


  const getFrequency = async () => { 
    const {data: res} = await axios.get(`${url}/web/frequency`);
    return res;
  };

  const getStates = async () => { 
    const {data: res} = await axios.get(`${url}/web/states`);
    return res;
  };


  const getCities = async (id) => { 
    const {data: res} = await axios.get(`${url}/web/states/${id}/cities`);
    return res;
  };

  const postQuote = async ({ 
    origin,
    business_line_id,
    frequency,
    city_id,
    client_name,
    company_name,
    phone_number,
    email,
    quote_services
  }) => {
    const {data: res} = await axios.post(`${url}/web/quote`,{ 
      origin,
      business_line_id,
      frequency,
      city_id,
      client_name,
      company_name,
      phone_number,
      email,
      quote_services});
    return res;
  };


  export default {
    getAllBusinessLine,
    getBattery,
    getCobertura,
    getFrequency,
    getStates,
    getCities,
    postQuote,
    getCobertura2
  }
  