import React, { useState, useEffect } from 'react';
import Clientes from '../../components/clientes/Clientes';
import Requests from './Request/Requests';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ReactHtmlParser from "react-html-parser";
import { Carousel } from "react-responsive-carousel";
import { useHistory } from 'react-router-dom'
import ReactPlayer from 'react-player'

function Services() {
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    const history = useHistory();
    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [datax, setDatax] = useState([])
    const [datax2, setDatax2] = useState([])
    const [datax3, setDatax3] = useState([])
    const [datax4, setDatax4] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [selectedIndex2, setSelectedIndex2] = useState(1);
    const [noContent, setNoContent] = useState(false)
    const [noticia, setNoticia] = useState()
    const [bandera, setBandera] = useState(false)
    
    const handleListItemClick = (event, index, item) => {
        setSelectedIndex(index);
        history.push(`/servicios${item.route}`);
    };
    const handleListItemClick2 = (event, index) => {
        setSelectedIndex2(index);
    };

    const getAllBussinessLine = async () => {
        try {
            Requests.getAll().then((e) => {
                setData(e)
                setDatax(e[0])
                if (e[0].list_services.length >= 1) {
                    setData2(e[0].list_services)
                } else { setData2(null); setNoContent(true) }
                // console.log('epale',e[0].list_services)
                if (e[0].list_videos.length >= 1) {
                    e[0].list_videos.map((a) => {
                        setNoticia(e[0].list_videos)
                        setBandera(true)
                    })
                }
                if (e[0].list_services.length >= 1) {
                    history.push(`/servicios${e[0].route}/${e[0].list_services[0].name}`);
                    //  console.log('si se armo')
                } else {
                    history.push(`/servicios${e[0].route}`);
                    //    console.log('no se armo')
                }
                setSelectedIndex(e[0].id);
                setSelectedIndex2(e[0].list_services[0].id_services)
                setDatax2(e[0].list_services[0])
                setDatax3(e[0].business_unit)
                setDatax4(e[0].list_clients)


            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getFirstId = async () => {
        try {
            Requests.getById(selectedIndex).then((e) => {
                setDatax(e)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getFirstId2 = async () => {
        // console.log('elid',selectedIndex2);
        try {
            Requests.getById2(selectedIndex2).then((e) => {
                setDatax2(e)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getById = async (a) => {
        try {
            Requests.getById(a).then((e) => {
                setDatax(e)
                setNoticia(e.list_videos)
                if (e.list_services.length <= 0) {
                    setNoContent(true)
                } else {
                    // console.log('este es el id',);
                    try {
                        setNoContent(false)
                        Requests.getById2(e.list_services[0].id_services).then((o) => {
                            setDatax2(o)
                        }).catch((error) => {
                            console.log(error)
                        })
                    } catch (error) {
                        console.log(error)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getById2 = async (a) => {
        try {
            Requests.getById2(a).then((e) => {
                setDatax2(e)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getAllBussinessLine();
        getFirstId();
        getFirstId2();
    }, []);


    return (
        <div>
            <div className="inner-top-bar w-full py-3">
                <div className="container" >
                    <div className="row">
                        <div className="col-sm-12 heading-toogle">
                            {data.map((item) => (
                                <List key={item.id}>
                                    <ListItem button style={selectedIndex == item.id ? selectedStyles : UnselectedStyles}
                                        selected={selectedIndex === item.id}
                                        onClick={(event) => { getById(item.id); console.log('evento', item); handleListItemClick(event, item.id, item); }}>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                </List>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Carousel
                showArrows
                autoPlay
                interval={3000}
                infiniteLoop
                swipeable
                emulateTouch
                showThumbs={false}
                showStatus={false}>
                <div title={datax.title} className="slider-businessLines" style={{ backgroundImage: `url(${mainUrl}/azure-storage/api/blob/download/?file=${datax.name_cover_image})`, height: '70vh' }}>
                </div>
            </Carousel>
            <section className="service-inner-descript">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1>{ReactHtmlParser(datax.name)}</h1>
                        </div>
                        <div className="col-sm-12">
                            <p>{ReactHtmlParser(datax.description_2)}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-inner-services">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: noContent === true ? 'center' : 'space-between', }}>
                                {noContent === true ? null
                                    :
                                    <div style={{ width: '25%', marginTop: 25 }}>
                                        <h1>Tipos de servicios</h1>
                                        {data2 === null ?
                                            null :
                                            data2.map((item) => (
                                                <List key={item.id_services} style={{ backgroundColor: 'white' }}>
                                                    <ListItem button style={selectedIndex2 == item.id_services ? selectedStyles2 : UnselectedStyles2}
                                                        selected={selectedIndex2 === item.id_services}
                                                        onClick={(event) => { getById2(item.id_services); handleListItemClick2(event, item.id_services); }}>
                                                        <ListItemText primary={item.name} />
                                                    </ListItem>
                                                </List>
                                            ))
                                        }


                                    </div>
                                }
                                {noContent === true ? null
                                    : <div style={{ flexDirection: 'column', display: 'flex', width: '70%', }}>
                                        <div style={{ backgroundImage: `url(${mainUrl}/azure-storage/api/blob/download/?file=${datax2.name_image})`, width: '100%', height: '70vh', backgroundSize: 'cover', backgroundPosition: 'center', marginTop: 25 }}></div>
                                        <h1>{datax2.name}</h1>
                                        <p>{ReactHtmlParser(datax2.description)}</p>
                                    </div>}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="branches">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 mb-4">
                            <h1 >Nuestras sucursales</h1>
                        </div>
                    </div>
                    <div className="row">
                        {datax3.map((item, index) => (
                            <div key={index} className="col-md-6 col-lg-4 mb-6" style={{ display: datax3.length > 0 ? null : 'none' }}>
                                <div className="flex-row flex inline-block w-100">
                                    <div className="img-container">
                                        <img alt={item.alt} title={item.title} className="" style={{ minHeight: 15, maxHeight: 20, minWidth: 15, maxWidth: 20, marginTop: 5 }}
                                            src={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_marker_image}`} />
                                    </div>
                                    <h4>{item.name}</h4>
                                </div>
                                <div className="divider-white"></div>
                                <ul>
                                    {datax3.map((item2, index2) => (
                                        <li style={{ cursor: 'pointer' }} onClick={() => {
                                            history.push(`/sucursales/${item2.id}`);
                                        }} >{item2.name}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="service-inner-video">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {bandera == true ?
                                <Carousel
                                    showArrows
                                    autoPlay
                                    interval={3000}
                                    infiniteLoop
                                    swipeable
                                    emulateTouch
                                    showThumbs={false}
                                    showStatus={false}>
                                    {noticia.map((e) => (
                                        <ReactPlayer controls width={"100%"} height={"80vh"} url={e.url_video} />
                                    ))}
                                </Carousel> : null}
                        </div>
                    </div>
                </div>
            </section>

            <Clientes />
        </div>
    )
}

export default Services

const selectedStyles = {
    backgroundColor: "rgb(0, 61, 165)",
    borderRadius: 31.5,
    color: 'white'
}
const selectedStyles2 = {
    background: "linear-gradient(90deg, #003DA5 95%, #84BD00 5%)",
    borderRadius: 0,
    color: 'white'
}
const UnselectedStyles = {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: 31.5,
    color: '#003DA5'
}
const UnselectedStyles2 = {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: 0,
    color: '#8C8C8C'
}