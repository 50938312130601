import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Requests from './Requests/Requests';
import { Link } from "react-router-dom";
import RespSoc from '../../pages/RespSocial/Requests/RequestResp';
import Servicios from '../../pages/servicios/Request/Requests';
import Secciones from '../../pages/InfoInst/Requests/InfoRequests';

function Footer() {
    let history = useHistory();
    const [contacts, setContacts] = useState([])
    const [respSoc, setRespSoc] = useState([])
    const [servicios, setServicios] = useState([])
    const [secciones, setSecciones] = useState([])

    useEffect(() => {
        getContacts()
        getRespSoc()
        getServicios()
        getSecciones()
    }, []);

    const getContacts = () => {
        try {
            Requests.getContacts().then((res) => {
                // console.log('CONTACTS',res)
                setContacts(res)
            })
        } catch (error) {
            //console.log(error)
        }
    }

    const getRespSoc = () => {
        try {
            RespSoc.getAllResp().then((res) => {
                //  console.log('Resp Soc',res)
                setRespSoc(res)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getServicios = () => {
        try {
            Servicios.getAll().then((res) => {
                //  console.log('Servicios',res)
                setServicios(res)
            })
        } catch (error) {
            // console.log(error)
        }
    }

    const getSecciones = () => {
        try {
            Secciones.getAllInformationInst().then((res) => {
                // console.log('Secciones',res)
                setSecciones(res)
            })
        } catch (error) {
            // console.log(error)
        }
    }

    const setDataSeoStorage = (data) => {
        window.sessionStorage.setItem("seoRA", JSON.stringify(data));
    }

    return (

        <>
            <footer className="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h4>Corporativo</h4>
                            <p>Boulevard Díaz Ordaz # 130, Torre 4, Piso 16 <br></br>Colonia Santa María, Monterrey, <br></br>N.L. México, C.P. 64650</p>
                            <p className="mb-3"><a className='taga-text-decorate' href={`tel:+52(81)81747474`}>Teléfono. + 52(81) 81747474</a></p>
                        </div>
                        <div className="col-md-6 offset-md-2">
                            <button className="button btn-action-primary btn-footer mb-3" onClick={() => { document.getElementById("contacto").click(); }}>
                                Ir a contacto
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="divider bc-white mb-3"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="col-20-per">
                                <h5>Nuestra empresa</h5>
                                <ul className="links-list">
                                    {secciones.map((v, i) =>
                                    (
                                        <li key={`footer-company${v.id}-${v.url}`} className="taga-text-decorate pointer-cursor-gral" onClick={() => {
                                            history.push(`/nuestra_empresa/${encodeURIComponent((v.element == 'Informes Anuales' || v.element == 'Presencia Nacional') ? v.element : v.url)}`);
                                            setDataSeoStorage({
                                                meta_description: v.meta_description,
                                                meta_keywords: v.meta_keywords,
                                                title_page: v.title_page,
                                                url: v.url
                                            });
                                        }}>
                                            {v.element}
                                        </li>
                                    )
                                    )}
                                </ul>
                            </div>
                            <div className="col-20-per">
                                <h5>Servicios</h5>
                                <ul className="links-list">
                                    {servicios.map((v, i) =>
                                    (
                                        <li key={`footer-services${v.id}`} className="taga-text-decorate pointer-cursor-gral"
                                            onClick={() => {
                                                history.push(`/detalle_linea/${v.url}`);
                                                setDataSeoStorage({
                                                    meta_description: v.meta_description,
                                                    meta_keywords: v.meta_keywords,
                                                    title_page: v.title_page,
                                                    url: v.url
                                                });
                                            }}>
                                            {v.name}
                                        </li>
                                    )
                                    )}
                                </ul>
                            </div>
                            <div className="col-20-per">
                                <h5>Programas</h5>
                                <ul className="links-list">
                                    {respSoc.map((v, i) =>
                                    (
                                        <li className="taga-text-decorate pointer-cursor-gral"
                                            onClick={() => { 
                                            history.push(`/resp_detalle/${v.url_page}`);   
                                            setDataSeoStorage({
                                                meta_description: v.meta_description,
                                                meta_keywords: v.meta_keywords,
                                                title_page: v.title_page,
                                                url: v.url_page
                                            });
                                            }} key={`footer-resp${v.id_program}`}>
                                            {v.program_name}
                                        </li>
                                    )
                                    )}
                                </ul>
                            </div>
                            <div className="col-20-per">
                            </div>
                            <div className="col-20-per">
                                <ul className="links-list" style={{ marginTop: '-15px' }}>

                                    {contacts.map((item, index) => (
                                        <li key={`footer-info${item.id_contacto}`}>
                                            <h5 className="mt-3">{item.name}</h5>
                                            {
                                                (item.email) &&
                                                <><a className="taga-text-decorate" href={`mailto:${item.email}`}>{`Email: ${item.email}`}</a><br></br></>
                                            }
                                            <a className="taga-text-decorate" href={`tel:${item.phone_number}`}>{`Teléfono: ${item.phone_number}`}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="divider bc-white mb-3"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <p>© 2021 Red Ambiental</p>
                        </div>
                        <div className="col-sm-9">
                            <ul className="links-subfooter">
                                <li>
                                    <button className='btn-footer-privacy' onClick={() => {
                                        history.push({
                                            pathname: '/aviso_privacidad',
                                        });
                                    }} >
                                        Aviso de privacidad
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
