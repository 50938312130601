/** @format */

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CotizadorBoton } from "./components/cotizador/CotizadorBoton";
import Footer from "./components/Footer/Footer";
import Header from "./components/header/Header";
import App from "./routes/App";
import "lazysizes";
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import { AddHelmet } from "./components/Helmet/AddHelmet";
import CookieConsent from "react-cookie-consent";
import axios from "axios";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { Error } from './error';

export const RedAmbiental = () => {
  const main = process.env.REACT_APP_API_TOKEN;
  axios.defaults.headers.common['authorizationToken'] = main;

  return (
    <Router>
      <Switch>
        <Route exact path='/error/404'>
          <Error />
        </Route>
        <Route>
          <AddHelmet></AddHelmet>
          <Header />
          <App></App>
          <CotizadorBoton></CotizadorBoton>
          <Footer />
          <CookieConsent buttonText='Aceptar' location='top'>
            Este sitio utiliza cookies.
          </CookieConsent>
        </Route>
      </Switch>
    </Router>
  );
};
