import React,{useState, useEffect} from 'react'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Button from "@mui/material/Button";
import Footer from './Footer'
import Requests from './Requests/Requests'
import { Modal } from '@material-ui/core'
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { SentimentVeryDissatisfiedRounded } from '@mui/icons-material';

var list2 = []

function Cotizador() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    let history = useHistory()
    const classes = useStyles();
    const theme = useTheme();
    const [NumQuestion, setNumQuestion]=useState(1)
    const [lineas,setLineas] = useState([])
    const [selectLinea,setSelectLinea] = useState()
    const [bateria,setBateria] = useState([])
    const [lineaSeleccionada,setLineaSeleccionada] = useState()
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [nombre,setNombre] = useState('')
    const [empresa,setEmpresa] = useState('')
    const [estado,setEstado] = useState('')
    const [ciudad,setCiudad] = useState('')
    const [telefono,setTelefono] = useState('')
    const [correo,setCorreo] = useState('')

    const [send,setSend] = useState(false)
    const [frecuencias,setFrecuencias] = useState([])
    const [selectFrecuencia,setSelectFrecuencia] = useState([])
    const [json,setJson] = useState([])
    const [itemActual,setItemActual] = useState([])
    const [itemPrev,setItemPrev] = useState([])
    const [optionSelected,setOptionSelected] = useState()
    const [modalCobertura, setModalCobertura]=useState(false);
    const [estados,setEstados] = useState([])
    const [ciudades,setCiudades] = useState([])
    const [itemSelect,setItemSelect] = useState([])
    const [list,setList] = useState([])
    const [itemList,setItemList] = useState([])
    const [pregunta,setPregunta] = useState()
    const [respuesta,setRespuesta]= useState()
    const [numeroSuc,setNumeroSuc] = useState('')
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
      // document.title="Red Ambiental - Cotizador"
      getLineas()
      getStates()
    }, []);

  const abrirCerrarModalEliminar=()=>{
    setModalCobertura(!modalCobertura);
  }

  const getLineas = ()  => {
    try {
      Requests
        .getAllBusinessLine()
        .then((e) => {
         // console.log("LINEAS----------------", e);
          setLineas(e);
          
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getStates = ()  => {
    try {
      Requests
        .getStates()
        .then((e) => {
          //console.log("STATES", e);
          setEstados(e);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getCity = (id)  => {
    try {
      Requests
        .getCities(id)
        .then((e) => {
          //console.log("CITY", e);
          setCiudades(e);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getCoberturaCity = (id)  => {

    try {
      Requests
        .getCobertura2(id,lineaSeleccionada)
        .then((e) => {
          //console.log("COBERTURA", e);
          if(e.length == 0){
            setModalCobertura(true)
          }else {
            setNumeroSuc(e[0].phone_number)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

    // try {
    //   Requests
    //     .getCobertura(id)
    //     .then((e) => {
    //       console.log("COBERTURA", e);
    //       if(e.length == 0){
    //         setModalCobertura(true)
    //       }else {
    //         setNumeroSuc(e[0].phone_number)
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  }

  const getPreguntas = (id) => {
    setLineaSeleccionada(id)
    try {
      Requests
        .getBattery(id)
        .then((e) => {
          //("PREGUNTAS", e);
          let base64ToString = Buffer.from(e.json, "base64").toString();
          base64ToString = JSON.parse(base64ToString);
          //console.log(base64ToString)
          setBateria(base64ToString)
          setItemActual(base64ToString)
          setFrecuencias(e.lst_frequency)
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const add_remove = (item) => {
    const itemIndex = selectFrecuencia.findIndex(item2 => item2 === item.selected_frequency);

    if (itemIndex > -1) {
  //    newList.splice(itemIndex, 1);
        setSelectFrecuencia(selectFrecuencia.filter(item2 => item2 !== item.selected_frequency));
    } else {
      setSelectFrecuencia(oldArray => [...oldArray, item.selected_frequency]);
      //newList.push(item);
    }
    // setSelectFrecuencia(oldArray => [...oldArray, newList]);
  }

  const check = (item) => {
    const itemIndex = selectFrecuencia.findIndex(item2 => item2 === item.selected_frequency);

    if (itemIndex > -1) {
       return false
    } else {
      return true
    }
  }

    const handleChangeSelect = (event) => {
      setCiudades([])
      const parseado = JSON.parse(event.target.value)
      setEstado(parseado.state) 
      getCity(parseado.id)
    }

    const handleChangeSelect2 = (event) => {
      const parseado = JSON.parse(event.target.value)
      //console.log(parseado)
      setCiudad(parseado)
      getCoberturaCity(parseado.id)
    }

  const renderAnswers = (itemActual) => {
    for(var i = 0; i < itemActual.length; i++){
      if(itemActual[i].type == "question"){
        
          return (
            <>
            {itemActual[i].children.map((item,index)=>(
              <div key={index} className="items-center justify-center" style={{cursor:'pointer',}}>
                <img onClick={()=>{
                  if(item.hasOwnProperty('children')){
                    setOptionSelected(item.children)
                    setItemSelect(item)
                  } else {
                    setOptionSelected(item)
                    setItemSelect(item)
                  }
                }} style={{width:140, maxWidth:140, height:140, maxHeight:140, borderRadius:360, 
                borderWidth:4, borderColor: itemSelect !== undefined ? itemSelect.title !== item.title ? '#787777':'#84BD00' : null}} 
                 src={item.file}
               />
                <p className="text-center trebuchet" style={{fontSize:14}}>{item.title}</p>
              
            </div>
          ))}
          {itemActual.map((item,index)=>(
            <div key={index} className="items-center justify-center" style={{cursor:'pointer',display: item.type == "answer" ? null : 'none'}}>
                <img onClick={()=>{setOptionSelected(item);setItemSelect(item)}} style={{width:140, maxWidth:140, height:140, maxHeight:140, borderRadius:360, 
                borderWidth:4, borderColor: itemSelect !== undefined ? itemSelect.title !== item.title ? '#787777':'#84BD00' : null}} 
                 src={item.file}
               />
                <p className="text-center trebuchet" style={{fontSize:14}}>{item.title}</p>
              
            </div>
          ))}
          </>
          )
        }

    }



        }

  const renderPreguntas = () => {
    for(var i = 0; i < itemActual.length; i++){
      if(itemActual[i].hasOwnProperty('children')){
      switch (itemActual[i].type) {
        case "question":
          return(
            <>
            <p className="trebuchetRegular lg:text-left text-center my-8" style={{fontSize:21}}>Para ayudarte a obtener el mejor servicio, por favor contesta las siguientes preguntas: </p>
            <h3 className="" >{itemActual[i].title}</h3>
              
               <div className="mt-10 flex flex-wrap flex-row justify-evenly justify-center items-center">
                {renderAnswers(itemActual)}
                </div>
                </>
          )
          break;
      
        default:
          break;
      }
    } else {
      setNumQuestion(4)
    }
    }
  }

  const Q_A = (question,answer) =>{
    const obj = {
      service:question,
      type:answer
    }
    setJson(oldArray => [...oldArray, obj]);
    setPregunta()
    setRespuesta()
  }

  const toastErr = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const enviar = () => {
    setDisabled(true)
    if(nombre == '' || nombre == undefined){
      toastErr("Campo nombre no puede ir vacío")
      setDisabled(false)
      return;
    }
    if(empresa == '' || empresa == undefined){
      toastErr("Campo empresa no puede ir vacío")
      setDisabled(false)
      return;
    }
    if(telefono == '' || telefono == undefined){
      toastErr("Campo teléfono no puede ir vacío")
      setDisabled(false)
      return;
    }
    if(telefono.length < 10){
      toastErr("Campo teléfono no puede ser menor a 10 dígitos")
      setDisabled(false)
      return;
    }
    if(correo == '' || correo == undefined){
      toastErr("Campo correo no puede ir vacío")
      setDisabled(false)
      return;
    }
    if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(correo)) {
      toastErr("El correo ingresado no es válido")
      setDisabled(false)
      return;
    }
    const post = {
      origin: "WEB",
      business_line_id: lineaSeleccionada,
      frequency: selectFrecuencia,
      city_id: ciudad.id,
      client_name: nombre,
      company_name: empresa,
      phone_number: telefono,
      email: correo,
      quote_services: json 
    }
    try {
      Requests.postQuote(post).then((res)=>{
        //console.log("RES",res)
        if(res.code == 200){
          setNumQuestion(6)
          setDisabled(false)
        }
        setDisabled(false)
      }).catch((err)=>{
       // console.log("ERROR",err)
        setDisabled(false)
      })
    } catch (error) {
      setDisabled(false)
    }
    // setNumQuestion(6)
  }

  const valueProgress = () => {
    switch (NumQuestion) {
      case 1:
        return 20
        break;
      case 2:
        return 40
        break;
      case 3:
        return 60
        break;
      case 4:
        return 80
        break;
      case 5:
        return 90
        break;
      case 6:
        return 100
      break;
      default:
        break;
    }
  }

  const checkEmail = () => {
    if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(correo)) {
      toastErr("El correo ingresado no es válido")
    }
  }

    return (
      <div>
        <ToastContainer position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        <header className="header-small">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-3 col-4">
                <div style={{cursor:'pointer'}} className="logo">
                  <img onClick={()=>{history.push('./')}} src={require('../../components/header/images/logo.png').default} title=""/> 
                </div>
              </div>
              <div className="col-md-10 col-sm-9 col-8">
                <h5 className="heading-title">Cotiza tu servicio</h5>
                <div className="progress-view">
                  <CircularProgress className="progress-chart" style={{color:'#84BD00'}} variant="determinate" value={valueProgress()} />
                  {NumQuestion == 6 ?
                  <p className="progress-numbers">Finalizado</p> : <p className="progress-numbers">{`Paso ${NumQuestion}/5`}</p> }
                </div>
              </div>
            </div>
          </div>
        </header>


        {/* <div className="w-full lg:px-24 px-10">
        <p className="trebuchetRegular lg:text-left text-center my-8" style={{fontSize:21}}>Para ayudarte a obtener el mejor servicio, por favor contesta las siguientes preguntas: </p>
        </div> */}
        <div className="w-full inner-page-container min-vh-wizard">
          { NumQuestion == 1 ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <p className="text-center">Para ayudarte a obtener el mejor servicio, por favor contesta las siguientes preguntas: </p>
                    <h4 className="mb-5">¿Cúal servicio necesitas?</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 wizard-options-container text-center">
                    {lineas.map((item,index)=>(
                      <div key={index} className="item-option" style={{cursor:'pointer',}}>
                        <div className="item-image" style={{borderWidth:selectLinea !== index ? 0 : 4, borderColor: selectLinea !== index ? null :'#84BD00'}}>
                          <img onClick={()=>{setSelectLinea(index);getPreguntas(item.id)}} src={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_quote_image}`}/>
                        </div>
                        <p className="small-title">{item.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )

          : NumQuestion == 2 ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <p className="text-center">Para ayudarte a obtener el mejor servicio, por favor contesta las siguientes preguntas: </p>
                    <h4 className="mb-5 text-center">¿En dónde te encuentras?</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 offset-sm-4 wizard-options-container text-center">
                  <select required className="mt-3 form-control" onChange={handleChangeSelect}>
                    <option label="Estado*"></option>
                    {estados.map((item,index)=>(
                        <option key={index} value={JSON.stringify(item)}>{item.state}</option>
                      ))}
                    </select>
                  <select className="mt-3 form-control" required onChange={handleChangeSelect2}>
                    <option defaultValue >Selecciona tu ciudad</option>
                    {ciudades.map((item,index)=>(
                        <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                      ))}
                  </select>
                  </div>
                </div>
              </div>
            </>
          ) 
          : NumQuestion == 3 ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <p className="text-center" >Para ayudarte a obtener el mejor servicio, por favor contesta las siguientes preguntas: </p>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-12 wizard-options-container text-center">  
                {itemActual.map((item,index)=>(
                <>
                    <h4 style={{display: item.type == "question" ? 'block' : "none"}} className="mb-5 text-center">{item.type ==  "question" ? item.title : null}</h4>
                    
                      {
                        item.hasOwnProperty('children') && (
                          item.children.map((item2,index2)=>(
                            <div key={index2} className="item-option">
                              <div className="item-image" 
                              style={{borderWidth:itemSelect !== undefined ? itemSelect.title !== item2.title ? 0:4 : null,borderColor: itemSelect !== undefined ? itemSelect.title !== item2.title ? null :'#84BD00' : null}}>
                                <img onClick={()=>{
                                  setPregunta(item.title)
                                  setRespuesta(item2.title)
                                  if(item2.hasOwnProperty('children')){
                                    // alert('tiene children')
                                    setOptionSelected(item2.children)
                                    setItemSelect(item2)
                                    if(list.length == 0){
                                      setItemList(itemActual)
                                    }else {
                                      setItemList(item)
                                    }
                                  } else {
                                    // alert('no tiene children')
                                    setOptionSelected([itemActual])
                                    setItemSelect(item2)
                                    setItemList(itemActual)
                                    //setNumQuestion(4)
                                  }
                                }} src={`${mainUrl}/azure-storage/api/blob/download/?file=${item2.file.nameFile}`}/>
                              </div>
                              <p className="small-title">{item2.title}</p>
                            </div>
                          ))
                        )
                      }
                      { item.type == "answer" && (
                          <div key={index} className="item-option">
                            <div className="item-image">
                              <img onClick={()=>{
                                setRespuesta(item.title)
                                setPregunta(itemActual.find(res => res.type == 'question').title)
                                //console.log("ITEM",item)
                                  if(item.hasOwnProperty('children')){
                                  setOptionSelected(item.children)
                                  setItemSelect(item)
                                  setItemList(item)
                                } else {
                                  setOptionSelected([itemActual])
                                  setItemSelect(item)
                                  //setNumQuestion(4)
                                  setItemList(itemActual)
                                }
                              }} style={{borderWidth:4,borderColor: itemSelect !== undefined ? itemSelect.title !== item.title ? '#787777':'#84BD00' : null}} src={item.file} />
                            </div>
                            <p className="small-title">{item.title}</p>
                          </div>
                        )
                      }
                      {/* {renderAnswers(itemActual)} */}
                    
                  
                </>
                ))}
                </div>
                </div>
              </div>
            </>
          )

          : NumQuestion == 4 ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <p className="">Para ayudarte a obtener el mejor servicio, por favor contesta las siguientes preguntas: </p>
                    <h4 className="mb-5">¿Cúales días de la semana necesitarías el servicio?</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 wizard-options-container text-center">
                    {frecuencias.map((item,index)=>(
                      <div onClick={()=>{add_remove(item)}} className="item-option-frecuency"  >
                        <div className="item-frecuency" style={{borderWidth:4, borderColor: check(item) ? '#787777':'#84BD00'}}>
                          <p className="item-text">{item.selected_frequency}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )     
          : NumQuestion == 5 ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <p className="">Por último, ingresa tus datos personales para que un asesor se ponga en contacto contigo </p>
                  </div>
                </div>

                <div className="row">

                  <div className="col-sm-8">

                    <div className="info-container">

                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-12">
                            <h4 className="mb-4">Datos personales</h4>
                          </div>
                          <div className="col-sm-12">
                            <input type="text" required className="form-control mb-3" placeholder="Nombre *" value={nombre} onChange={(e)=>{setNombre(e.target.value)}} />
                          </div>
                          <div className="col-sm-12">
                            <input type="text" required className="form-control mb-3" placeholder="Empresa *" value={empresa} onChange={(e)=>{setEmpresa(e.target.value)}} />
                          </div>
                          <div className="col-sm-6">
                            <input disabled type="text" required className="form-control mb-3" placeholder="Estado *" value={estado} onChange={(e)=>{setEstado(e.target.value)}} />
                          </div>
                          <div className="col-sm-6">
                            <input disabled type="text" required className="form-control mb-3" placeholder="Ciudad *" value={ciudad.name} onChange={(e)=>{}} />
                          </div>
                          <div className="col-sm-6">
                            <input onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                }} required maxLength={10} type="number" required className="form-control mb-3 no-arrow" placeholder="Teléfono *" value={telefono} onChange={(e)=>{setTelefono(e.target.value)}} />
                          </div>
                          <div className="col-sm-6">
                            <input type="email"onBlur={()=>{checkEmail()}} required className="form-control mb-3" placeholder="Correo electrónico *" value={correo} onChange={(e)=>{setCorreo(e.target.value)}} />
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                  <div className="col-sm-4">
                    <div className="checkout-container">
                      <h4 className="mb-2">Resumen de cotización</h4>
                      <ul className="checkout-info">
                        {json.map((item,index)=>(
                          <li key={index}>
                            <CheckCircleIcon style={{color:'#003DA5',}}/><p><span>{item.service}:</span><br/>{item.type}</p>
                          </li>
                        ))}
                        <li>
                          <CheckCircleIcon style={{color:'#003DA5'}}/><p><span>Frecuencia:</span><br/>{selectFrecuencia.join(', ')}</p>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </>
          )
          : NumQuestion == 6 ?
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h3 className="text-center">¡Listo, tu cotización está en proceso!</h3>
                <CheckCircleIcon className="mt-10 mb-4" style={{color:'#003DA5',fontSize:80}} />
                <p className="text-center">En la brevedad uno de nuestros asesores se pondrá en contacto contigo.</p>
                <p className="text-center">Si necesitas ayuda puedes marcarnos al <strong style={{fontFamily:'Roboto', fontSize:'inherit'}}>{numeroSuc}</strong></p>
              </div>
            </div>
          </div>  
          : null}

        </div>

         
         
        <footer className="wizard">

          <div className="controls">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    {
                      NumQuestion == 1 ?
                      <button className="button btn-action-primary" type="button" onClick={()=>{setNumQuestion(NumQuestion - 1)}} style={{display: NumQuestion == 1 ? 'none' : 'block'}}>Anterior</button> 
                    
                      : NumQuestion == 2 ?
                    <button className="button btn-action-primary" type="button" onClick={()=>{setNumQuestion(NumQuestion - 1);setCiudad('')}} style={{display: NumQuestion == 1 ? 'none' : 'block'}}>Anterior</button> 


                    : NumQuestion == 3 ? 

                    <button className="button btn-action-primary" type="button" onClick={()=>{
                      if(list.length > 0){
                        // alert("true")
                        //console.log(list[list.length-1]);
                      //console.log(list.pop())
                      if(Array.isArray(list[list.length-1])){
                        setItemActual(list[list.length-1]);
                      }else {setItemActual([list[list.length-1]]);}
                      setItemSelect([])
                      // // console.log("LIST",list)
                      setList(list.filter((item2,index) => index !== list.length-1));
                      setJson(json.filter((item,index)=> index !== json.length-1))
                      setOptionSelected()
                      } else {
                        setNumQuestion(2)}
                    }} style={{display: NumQuestion == 1 ? 'none' : 'block'}}>Anterior</button>

                    : NumQuestion == 4 ?
                    
                    <button className="button btn-action-primary" type="button" onClick={()=>{
                      setNumQuestion(3)
                      if(list.length > 0){
                        // alert("true")
                       // console.log(list[list.length-1]);
                      //console.log(list.pop())
                      if(Array.isArray(list[list.length-1])){
                        setItemActual(list[list.length-1]);
                      }else {setItemActual([list[list.length-1]]);}
                      setItemSelect([])
                      // // console.log("LIST",list)
                      setList(list.filter((item2,index) => index !== list.length-1));
                      setJson(json.filter((item,index)=> index !== json.length-1))
                      setOptionSelected()
                      } else {
                        setNumQuestion(2)}
                    }} style={{display: NumQuestion == 1 ? 'none' : 'block'}}>Anterior</button>

                    : NumQuestion == 5 ?
                    <button className="button btn-action-primary" type="button" onClick={()=>{setNumQuestion(NumQuestion - 1)}} style={{display: NumQuestion == 1 ? 'none' : 'block'}}>Anterior</button> 

                    : NumQuestion == 6 ?
                    <button className="button btn-action-primary" type="button" onClick={()=>{setNumQuestion(NumQuestion - 1)}} style={{display: NumQuestion == 6 ? 'none' : 'block'}}>Anterior</button> 

                    :
                    null }
                  </div>


                  <div className="col-6">
                    {
                    NumQuestion == 1 ?
                    <button disabled={selectLinea == undefined ? true : false} className="button btn-action-primary f-right" type="button" onClick={()=>{setNumQuestion(NumQuestion + 1)}} style={{backgroundColor:selectLinea == undefined ? 'gray' : "rgb(0, 61, 165)"}}>Siguiente</button> 
                    :
                    NumQuestion == 2 ?
                    <button className="button btn-action-primary f-right" disabled={ciudad == '' ? true : false} type="button" onClick={()=>{setNumQuestion(NumQuestion + 1)}} style={{backgroundColor:ciudad == '' ? 'gray' : "rgb(0, 61, 165)"}}>Siguiente</button>
                    : NumQuestion == 3 ?
                    <>
                    <button className="button btn-action-primary f-right" disabled={optionSelected == undefined ? true : false} type="button" onClick={()=>{
                      var arr = optionSelected[0]
                      // alert(JSON.stringify(arr, null, 4));
                      if(arr.hasOwnProperty('children')){
                        Q_A(pregunta,respuesta)
                        setList(oldArray => [...oldArray, itemList]);
                        // alert("true")
                        setItemActual(optionSelected); setOptionSelected();setItemSelect([]);
                      }else{ 
                        Q_A(pregunta,respuesta)
                        setList(oldArray => [...oldArray, itemList]);
                        // alert("false")
                        setNumQuestion(4) }
                      }} style={{backgroundColor:optionSelected == undefined ? 'gray' : "rgb(0, 61, 165)"}}>Siguiente</button>
                    </>
                    : NumQuestion == 4 ?
                    <button disabled={selectFrecuencia.length == 0 ? true : false} className="button btn-action-primary f-right" type="button" onClick={()=>{setNumQuestion(NumQuestion + 1)}} style={{backgroundColor:selectFrecuencia.length == 0 ? 'gray' : "rgb(0, 61, 165)",}}>Siguiente</button>
                    : NumQuestion == 5 ?
                    <div className="flex flex-row button btn-action-primary f-right" style={{backgroundColor:disabled ? 'gray' : "rgb(0, 61, 165)"}}>
                      <button disabled={disabled} className="button btn-action-primary f-right" type="button" onClick={()=>{enviar()}} style={{backgroundColor:disabled ? 'gray' : "rgb(0, 61, 165)"}} >Enviar</button>
                      {disabled ?<CircularProgress />:null}
                    </div>
                    : NumQuestion == 6 ?
                    <button  className="button btn-action-primary f-right" type="button" onClick={()=>{history.push('./')}}>Aceptar</button> 
                    : null}
                  </div>
                </div>
              </div>

          </div>
          <Footer/>
        </footer>     
        <Modal open={modalCobertura}>      
          <div className={classes.modal}>
            <div style={{alignItems:'center',display:'flex',justifyContent:'center'}}>
              <ErrorIcon style={{color:'#003DA5',fontSize:60}} fontSize={'large'} />
            </div>
            <p style={{textAlign:'center',marginBlock:50}}>Por el momento no contamos con cobertura en tu ciudad</p>
            <div style={{marginBlock:40, width:'100%', alignItems:'flex-end'}}>
              <Button style={{width:'45%', background:'#003DA5', justifyContent:'center',float:'right', color:'white'}} onClick={()=>{setCiudad('');setModalCobertura(false)}}>ACEPTAR</Button>
            </div> 
          </div> 
        </Modal> 
      </div>
    )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    width: 350,
    backgroundColor: theme.palette.background.paper,
    borderRadius:5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default Cotizador
