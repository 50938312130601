import React, { useState, useEffect } from 'react';
import { IconButton, } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import SectionRequest from './request/SectionRequest'
import { useHistory } from 'react-router-dom'
import LazyLoad from 'react-lazy-load';

export default function Toolbar1() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const history = useHistory();

  const [datas, setDatas] = useState([
    { id: 1, client: '0001147 - Lala SA de CV' },
    { id: 2, client: '0001147 - Soriana SA de CV' }
  ])
  const [data, setData] = useState([])

  useEffect(() => {
    try {
      SectionRequest.getAllSocial().then((res) => {
        setData(res.map((e) => {
          return {
            ...e,
          }
        }))
      })
    } catch (error) {
      // console.log(error)
    }
  }, [])
  // const classes = useStyles();
  return (
    <div className="header-top">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <ul className="nav-top-left f-left">
              <li><IconButton size="large" edge="start" color="inherit" aria-label="menu"> <LocalPhoneIcon /></IconButton><a className="taga-text-decorate" href={`tel:800 733 22 22`}>800 733 22 22</a></li>
              <li style={{ width: '300px' }}>
                <div className="social-media-icons">
                  {data.map((e) => (
                    <LazyLoad>
                      <IconButton className='toolbar1-icon' key={`toolbar${e.id}`} size="large" edge="start" color="inherit" aria-label="menu" href={e.url} target="_blank">
                      <img src={`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_image}`} alt={e.alt} title={e.title} width="26" height="26"/></IconButton>
                    </LazyLoad>
                  ))}
                </div>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>

          <div className="col-sm-6">
            <ul className="nav-top-right f-right">
              {/* <li><IconButton size="large" edge="start" color="inherit" aria-label="menu"><LocationOnIcon/></IconButton></li> */}
              {/* {
                  <li className="search">
                    <TextField className="search" InputProps={{disableUnderline: true,style:{height:'33px', width:'300px', margin:'0px'}}}
                      type="search"
                      style={{height:'fit-content'}}
                      placeholder="Buscar"
                      variant="standard"
                      onKeyPress= {(e) => {
                        if (e.key === 'Enter') {
                          history.push(`/busqueda?search=${e.target.value}`);
                        }
                      }}
                    />
                  </li>
                } */}
              {/* <li><IconButton onClick={() => { window.open('http://portalredb2c-qa.azurewebsites.net/', '_blank'); }} className="topBar-btn" edge="start" color="inherit"><PersonIcon />Portal clientes</IconButton></li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
