import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const url = `${mainUrl}/web-page-conf/web/business-line/detail`

  const getAllBusinessLine = async () => { 
    const {data: res} = await axios.get(`${url}`);
    return res;
  };

  export default {getAllBusinessLine}