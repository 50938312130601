import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
 const baseUrl = `${mainUrl}/web-page-conf`

const getAll = async (search) => { 
      const {data: res} = await axios.get(`${baseUrl}/web/search/getAll?search=${search}`);
      return res;
  };

  export default {
    getAll
  }