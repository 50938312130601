import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL

export const getUrlFile = (name) => {
    return `${mainUrl}/azure-storage/api/blob/download/?file=${name}`;
}

export const getRemoteFile = async (url) =>
    await JSZipUtils.getBinaryContent(
        getUrlFile(url)
    );

export const getBase64Ext = (data) =>
    data.split('.')[1];

export const zipFiles = (zip, data) => {
    data.forEach((i, c) => {

        const name = i.image ? i['name_image'] : i['name_file'];
        const url =  i.image ? i['url_image'] : i['url_file'];

        zip.file(
            name, 
            getRemoteFile(name),
        );
    });
}

export const generateZip = (data, filename) =>{
    const zip = new JSZip();

    zipFiles(zip, data.filter(i => i.image));
    zipFiles(zip, data.filter(i => !i.image));
    
    zip.generateAsync({type:"blob"}).then(function(content) {
        // see FileSaver.js
        saveAs(content, filename);
    });
}


export const tagImages = (data) =>
    data.map(i => {
        i.image = true;
        return i;
    });

