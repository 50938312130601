/** @format */

import React from "react";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

export const Error = () => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
          position: "fixed",
          top: "50%",
          left: "50%",
          marginTop: "-100px",
          marginLeft: "-200px",
          padding: "10px"
        }}
      >
        <a href='javascript:;'>
          <img style={{ cursor: "pointer", maxHeight: '100px' }} src={require("./components/header/images/logo.png").default} title='Red ambiental' className='logo' />
        </a>
        <h1>Página no encontrada</h1>
      </div>
    </>
  );
};
