import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
 const baseUrl = `${mainUrl}/business`
 const baseUrl2 = `${mainUrl}/business-line/admin`
 const baseUrl3 = `${mainUrl}/web-page-conf`

const getAllBusinessUnit = async () => { 
    const {data: res} = await axios.get(`${baseUrl}/admin/businessunit/getallbusinessunitpublicated
    `);
    return res;
  };

  const getAllBusinessLine = async () => { 
                                                                  //quitarle active para consumir de donde estaba antes
    const {data: res} = await axios.get(`${baseUrl2}/business-line/active`);
    return res;
  };

  const getAllBusinessLine2 = async () => { 
    //quitarle active para consumir de donde estaba antes
const {data: res} = await axios.get(`${baseUrl3}/web/business-line/markers`);
return res;
};

const getPresence = async () => { 
const {data: res} = await axios.get(`${baseUrl3}/web/presence`);
return res;
};


const getCorporativo = async () => { 
  const {data: res} = await axios.get(`${baseUrl3}/web/business-unit/offices`);
  return res;
  };

  export default {
    getAllBusinessUnit,
    getAllBusinessLine,
    getAllBusinessLine2,
    getPresence,
    getCorporativo
  }