import React, { useState, useEffect, useRef } from 'react';
import Requests from './Requests/Requests';
import ReactHtmlParser from "react-html-parser";
import Button from '@mui/material/Button';
import { useHistory, useParams } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import calendar from './icon-calendar.png'
import people from './icon-users.png'
import location from './icon-location.png'
import MapSucursal from './MapSucursal'
import { AddHelmet } from '../../components/Helmet/AddHelmet';

function Sucursales() {
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    const { name, zoneName } = useParams();
    let history = useHistory();

    const [data, setData] = useState();
    const [selectZone, setSelectZone] = useState()
    const regex = /(<([^>]+)>)/ig;


    const getData = async () => {
        const json = { name: decodeURIComponent(name) }
        try {
            Requests.getByName(json).then((e) => {
                setData(e)
                if (e.zones) {
                    if (e.zones.length > 0) {
                        var arreglo = e.zones.filter((value) => value.title.replace(regex, '').trim() == zoneName)
                        setSelectZone(arreglo[0])
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getData()
    }, [zoneName, name]);

    return (
        <div>
            <AddHelmet data={{ title: 'Sucursales | ' + data?.name, description: 'Desc' }}></AddHelmet>
            <div className="w-full inner-page-container pb-4">

                {data ?
                    <>
                        <div className="banner-location" style={{ backgroundImage: `url(${mainUrl}/azure-storage/api/blob/download/?file=${data.name_image})`, backgroundSize: 'cover', boxShadow: 'inset 0 0 0 1000px rgb(0 0 0 / 20%)' }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Button className="btn-action-back my-2 btn-sucursal-back"
                                            startIcon={<KeyboardBackspaceIcon style={{ color: 'white' }} />}
                                            onClick={() => { history.goBack() }}><h6>Regresar</h6></Button>
                                    </div>
                                    <div className="col-sm-12">
                                        <h1>{data.name}</h1>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <section className="location-block">

                            <div className="container">
                                <div className="row">

                                    <div className="col-sm-4 mb-4">
                                        <div className="location-detail">
                                            <div className="icon">
                                                <img src={location} />
                                            </div>
                                            <div className="location-info">
                                                <h5>Dirección de sucursal</h5>
                                                <p>{data.street}<br />{`${data.ext_number} ${data.sector}`}
                                                    <br />{data.ubication.state}<br />Tel.{data.phone_number}</p>
                                                <button className="btn-show-maps" type="button" onClick={() => {
                                                    window.open(`https://maps.google.com/?q=${data.latitude},${data.longitude}`, '_blank');
                                                }}
                                                >Ver en maps</button>
                                            </div>
                                        </div>
                                    </div>

                                    {data.population && data.start_services ?
                                        ReactHtmlParser(data.population)[0].props.children[0] == '0' && ReactHtmlParser(data.start_services)[0].props.children[0] == '0' ?
                                            <div className="col-sm-8">
                                                <MapSucursal latSuc={Number(data.latitude)} longSuc={Number(data.longitude)} />
                                            </div>
                                            :
                                            <>
                                                <div className="col-sm-4">
                                                    <div className="location-detail">
                                                        <div className="icon">
                                                            <img src={calendar} />
                                                        </div>
                                                        <div className="location-info">
                                                            <h5>Inicio de servicios</h5>
                                                            <p>{data.start_services ? ReactHtmlParser(data.start_services.replace(regex, '')) : null}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="location-detail">
                                                        <div className="icon">
                                                            <img src={people} />
                                                        </div>
                                                        <div className="location-info">
                                                            <h5>Población que se atiende</h5>
                                                            <p>{data.population ? ReactHtmlParser(data.population.replace(regex, '')) : null}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        :
                                        <>
                                            <div className="col-sm-4">
                                                <div className="location-detail">
                                                    <div className="icon">
                                                        <img src={calendar} />
                                                    </div>
                                                    <div className="location-info">
                                                        <h5>Inicio de servicios</h5>
                                                        <p>{data.start_services ? ReactHtmlParser(data.start_services.replace(regex, '')) : null}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="location-detail">
                                                    <div className="icon">
                                                        <img src={people} />
                                                    </div>
                                                    <div className="location-info">
                                                        <h5>Población que se atiende</h5>
                                                        <p>{data.population ? ReactHtmlParser(data.population.replace(regex, '')) : null}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                        </section>

                        {ReactHtmlParser(data.population)[0].props.children[0] !== '0' && ReactHtmlParser(data.start_services)[0].props.children[0] !== '0' &&
                            <section className="zones-block">

                                <div className="container">
                                    <div className="row">

                                        {(data.zones && data.zones.length > 0) ?
                                            <div className="col-sm-3">
                                                <div className='bg-white pb-4'>
                                                    <div className='bg-blue'>
                                                        <h5 className='p-3'>Consulta tus zonas de servicio</h5>
                                                    </div>
                                                    {data.zones.map((item, index) => (
                                                        <div key={index} className="mb-2">
                                                            <div className="px-3" >

                                                                <div className="d-flex px-3 flex-row py-2"
                                                                    onClick={() => {
                                                                        setSelectZone(item)
                                                                    }} >
                                                                    <div className="" style={{ color: '#84BD00', backgroundColor: '#84BD00', width: 5, marginRight: 5, display: item.id == selectZone?.id ? null : 'none' }}>

                                                                    </div>
                                                                    <h6 className='pointer-cursor-gral mt-0 mb-0'>{ReactHtmlParser(item.title.replace(regex, ''))}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div> : null}
                                        {(data.zones && data.zones.length > 0) ?
                                            <>
                                                {(selectZone) ?
                                                    <div className="offset-sm-1 col-sm-8 bg-white p-4">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                {selectZone.frequency !== "" &&
                                                                    <div className="col-sm-6">
                                                                        <h5>Frecuencia:</h5>
                                                                        <p>{ReactHtmlParser(selectZone.frequency)}</p>
                                                                    </div>}
                                                                {selectZone.timetable !== "" &&
                                                                    <div className="col-sm-6">
                                                                        <h5>Horario:</h5>
                                                                        <p>{ReactHtmlParser(selectZone.timetable)}</p>
                                                                    </div>}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12 text-center">
                                                                    <img title={selectZone.title_img} alt={selectZone.alt} className="zone-image" src={`${mainUrl}/azure-storage/api/blob/download/?file=${selectZone.image_name}`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>

                                                        </div>

                                                    </div> : null}
                                            </>
                                            : null}
                                    </div>
                                </div>
                            </section>}
                    </>
                    : null}
            </div>
        </div>
    )
}

export default Sucursales
