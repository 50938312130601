import React, { useState, useEffect, useRef } from 'react';
import Requests from './Requests/RequestResp';
import ReactHtmlParser from "react-html-parser";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom'
import { Carousel } from "react-responsive-carousel";
import { AddHelmet } from '../../components/Helmet/AddHelmet';
import LazyLoad from 'react-lazy-load';

function RespSocial() {
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    let history = useHistory()
    const [data, setData] = useState([]);
    const [contentMain, setContentMain] = useState([]);
    const [images, setImages] = useState([]);

    const getAllResponsabilities = async () => {
        try {
            Requests.getAllResp().then((e) => {
                setData(e)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getMaincontent = async () => {
        try {
            Requests.getMainContent().then((e) => {
                setContentMain(e);
                if (e.photos) {
                    setImages(e.photos);
                }
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllResponsabilities();
        getMaincontent();
    }, []);

    const setDataSeoStorage = (data) => {
        window.sessionStorage.setItem("seoRA", JSON.stringify(data));
    }

    return (
        <div>
            <Carousel
                showArrows
                showThumbs={false}
                showStatus={false}>
                {images.map((data, index) => (
                    <div key={`clientes${data.id_image}`} className="slider-resp-social lazyload" data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${data.name_image.replace(" ", "%20")}`} title={data.title}>
                        <div className="container z-10">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2 className="mb-2">&nbsp;</h2>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <h1>&nbsp;</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="container-businessLine2">
                                        <p id="parrafo2" className="parrafo contenedor">
                                            {ReactHtmlParser(contentMain.description)}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </Carousel>

            <div className="w-full inner-page-container p-4 background-custom">
                <div className='container'>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="my-4">
                                <h2>{ReactHtmlParser(contentMain.content)}</h2>
                            </div>                           
                            <br></br>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        {data.map((e) => (
                            <div key={e.id_program} className="col-sm-12 display-grid">
                                <div className="card-article2">
                                        <div className="card-article-image lazyload" data-bg={`${mainUrl}/azure-storage/api/blob/download/?file=${e.cover_photo}`} title={e.title}>

                                            <div className="content-programs-rs">
                                                <h2 className='mb-2'>{e.program_name}</h2>
                                                <p id="parrafo2" className="parrafo">
                                                    {ReactHtmlParser(e.program_description)}
                                                </p>
                                            </div>
                                            <Button style={{ textTransform: 'inherit' }} className="btn-action-secundary btn-show-more-rs article-card-button btn-new2" onClick={() => {
                                                history.push({
                                                    pathname: `/resp_detalle/${e.url_page}`,
                                                    state: { detail: e.id_program }
                                                });
                                                setDataSeoStorage({
                                                    meta_description: e.meta_description,
                                                    meta_keywords: e.meta_keywords,
                                                    title_page: e.title_page,
                                                    url: e.url_page
                                                });
                                            }} endIcon={<ArrowRightAltIcon />}>
                                                Ver más
                                            </Button>

                                        </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RespSocial
