import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import pdf from '../../assets/pdf.png';
import {
  getallFiles,
  getallImages,
  getByTitle
} from "./Requests/Requests";
import {
  generateZip,
  tagImages,
  getUrlFile
} from "./Utils/zip";
import {
  CropOriginal,
} from '@mui/icons-material';
import { AddHelmet } from '../../components/Helmet/AddHelmet';

function Blog() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  let history = useHistory();
  const location = useLocation();
  const { name } = useParams();

  const [item, setItem] = useState({});
  const [imgs, setImgs] = useState([]);
  const [files, setFiles] = useState([]);

  const handleImages = async () => {
    generateZip(tagImages(imgs), 'imagenes');
  };

  const handleFiles = async () => {
    generateZip(files, 'archivos');
  };

  useEffect(async () => {
    const resp = await getByTitle(name)
    const respI = await getallImages(resp.id);
    const respF = await getallFiles(resp.id);

    setItem(resp);
    setImgs(respI);
    setFiles(respF)
  }, []);

  return (
    <div>
      <AddHelmet data={{ title: 'Detalle sala de prensa', description: 'Desc' }}></AddHelmet>
      <>
        <div className="inner-page-container p-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Button className="btn-action-back my-2" startIcon={<KeyboardBackspaceIcon />}
                  onClick={() => { history.push('/prensa') }}>REGRESAR</Button>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card-block press-card">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-12">
                        <h6 className="category-name">Sala de Prensa</h6>
                      </div>
                      <div className="col-sm-12 mb-3">
                        <h1 className="title-full">{item.title}</h1>
                      </div>
                      <div className="col-sm-12">
                        {item.name_image && <img className="image-full" src={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_image}`} />}
                      </div>

                      <div className="col-sm-12">
                        <div className="divider"></div>
                      </div>
                      <div className="col-sm-6">
                        <h5 className="subtitle">Imágenes</h5>
                      </div>
                      <div className="col-sm-6 text-center">
                        {imgs.length > 0 &&
                          <button
                            className="button btn-action-secundary f-right button-download"
                            onClick={() => handleImages()}
                          >
                            <CropOriginal className="mr-2" />
                            Descargar imágenes
                          </button>
                        }
                      </div>

                      <div className="col-sm-12 mb-2 mt-3">
                        {imgs.map((img, index) => (
                          <div className="image-preview-container" key={index}>
                            {img.name_image && <img className="image-download-preview" src={`${mainUrl}/azure-storage/api/blob/download/?file=${img.name_image}`} />}
                          </div>
                        ))}
                      </div>

                      <div className="col-sm-12">
                        <div className="divider"></div>
                      </div>
                      <div className="col-sm-6">
                        <h5 className="subtitle">PDFs</h5>
                      </div>
                      <div className="col-sm-6 text-center">
                        {files.length > 0 &&
                          <button
                            className="button btn-action-secundary button-download"
                            onClick={() => handleFiles()}
                          >
                            <CropOriginal className="mr-2" />
                            Descargar PDF
                          </button>
                        }
                      </div>

                      <div className="col-sm-12 mb-2 mt-3">
                        {files.map((file, index) => (
                          <a DOWNLOAD href={getUrlFile(file.name_file)} target="_blank">
                            <div key={index} className="pdf-preview-container">
                              <img className="pdf-download-preview" src={pdf} />
                              <h6>{file.name_file}</h6>
                            </div>
                          </a>
                        ))}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default Blog