import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import imagePressRoom from './../../assets/img-pressRoom.jpg';
import Requests from './Requests/Requests'
import ReactHtmlParser from "react-html-parser";
import LazyLoad from 'react-lazy-load';

function PressRoom() {
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    let history = useHistory()
    const [noticia, setNoticia] = useState()
    const regex = /(<([^>]+)>)/ig;

    useEffect(() => {
        getUltimaNoticia()
    }, []);

    const getUltimaNoticia = () => {
        try {
            Requests.getLastNew().then((res) => {
                //    console.log('LAST',res)
                if (res.text_publication && res.text_publication.length > 0) {
                    let newString = res.text_publication;
                    let otherStr = '';
                    res.text_publication = '';
                    newString = newString.match(/<p>.*?<\/p>/g);
                
                    if(newString.length > 0 && newString.length > 2) {
                        newString.forEach((element, index) => {
                            if (index < 1) {
                                otherStr = otherStr + element;
                            }
                        });
                    }    
                    res.text_publication = otherStr;
                }
                setNoticia(res)
            })
                .catch((err) => {
                    console.log(err)
                })
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            {noticia ?
                <section className="section-home-pressroom background-clients">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="heading-title mb-5">Noticias</h2>
                            </div>
                        </div>
                        <div className="row pointer-cursor-gral" onClick={() => {
                             history.push(`/Blog_Detalle/${encodeURIComponent(noticia.url)}`)
                        }}>
                            <div className="col-md-4">
                                {/* <LazyLoad> */}
                                    <div class="img-pressroom-container">
                                        <img className="img-pressroom lazyload" data-src={`${mainUrl}/azure-storage/api/blob/download/?file=${noticia.name_cover_image}`} alt={noticia.alt} title={noticia.title}/>
                                    </div>
                                {/* </LazyLoad> */}
                            </div>
                            <div className="col-md-8">
                                <div className="pressroom-container">
                                    <h3 className="mb-3">{noticia.title_publication}</h3>
                                    <p className='parrafo3' style={{marginButton:'10px'}}>{ReactHtmlParser(noticia.text_publication)}</p>
                                    <Button className="btn-action-secundary button-pressroom" style={{marginTop:'10px'}} endIcon={<ArrowRightAltIcon />}>
                                        Continua leyendo
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : null
            }
        </>
    )
}

export default PressRoom
