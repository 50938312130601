import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Requests from './Requests/RequestsPreg';
import ReactHtmlParser from "react-html-parser";
import Accordion from 'react-bootstrap/Accordion';
import { AddHelmet } from '../../components/Helmet/AddHelmet';
import { useHistory, useLocation } from 'react-router-dom';

function Blog() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  let history = useHistory()
  const location = useLocation();
  const [idPreg, setIdPreg] = useState()
  const [Allpreguntas, setAllPreguntas] = useState([])
  const [idGrouper, setIdGrouper] = useState();
  const [pregunta, setPregunta] = useState(null);
  const [dataSeo, setDataSeo] = useState({});

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0)
      if (location.state) {
        setIdPreg(location.state.id)
        setIdGrouper(location.state.id_grouper)
        await getPreguntaId(location.state.id)
        await getAllQuestions()
      } else {
        history.push('/')
      }
    })();

  }, []);

  const getPreguntaId = async (id) => {
    try {
      Requests.getPreguntaId(id).then((e) => {
        // console.log("PREGUNTA---",e)
        setPregunta(e)
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAllQuestions = async () => {
    try {
      Requests.getAllPreguntas().then((e) => {
        // console.log("PREGUNTAS---",e)
        setAllPreguntas(e)
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const NewQuestion = (id, id2) => {
    setIdPreg(id)
    getPreguntaId(id)
    setIdGrouper(id2)
  }
  
  const setDataSeoStorage = (data) => {
      setDataSeo(data);
      window.sessionStorage.setItem("seoRA", JSON.stringify(data));
  }

  return (
    <div>
      <AddHelmet data={dataSeo}></AddHelmet>
      <>
        <div className="w-full inner-page-container p-4">
          <div className="container">
            <Button className="btn-action-back my-2" style={{ color: '#003DA5' }} startIcon={<KeyboardBackspaceIcon />}
              onClick={() => { history.push('./') }}>REGRESAR</Button>
            <div className="row">
              <div className="col-sm-8 bg-white" style={{ height: 'fit-content' }}>
                {pregunta !== null ?
                  <div className="p-4">
                    <h6 style={{ color: '#003DA5' }}>{pregunta.grouper}</h6>
                    <br />
                    <h2 className="mb-6">{pregunta.question}</h2>
                    <p className='rich-text-editor'>
                      {ReactHtmlParser(pregunta !== null ? pregunta.answer : null)}
                    </p>
                    <br />
                    {(pregunta.name_image !== null && pregunta.name_image !== "" && pregunta.name_image !== " " && pregunta.name_image !== undefined) && (
                      <div className="inner-section-carousel" title={pregunta.title} style={{ backgroundImage: ` url(${mainUrl}/azure-storage/api/blob/download/?file=${pregunta.name_image})`, }}>
                      </div>
                    )}
                  </div>
                  :
                  <CircularProgress />
                }
              </div>

              <div className="col-sm-3 offset-sm-1 bg-white" style={{ height: 'fit-content' }}>
                {Allpreguntas.length > 0 ?
                  <Accordion defaultActiveKey={idGrouper}>
                    {Allpreguntas.map((item, index) => (
                      <Accordion.Item key={index} className="accordion-item" style={{ outline: 'none', border: null }} eventKey={item.id}>
                        <Accordion.Header className='custom-header-qa' >{item.name}</Accordion.Header>
                        <Accordion.Body>
                          {
                            item.frequent_questions.map((item2, index2) => (
                              <div key={index2} className="li-question d-flex flex-row">
                                {idPreg == item2.id && (
                                  <div className="" style={{ color: '#84BD00', backgroundColor: '#84BD00', width: 5, marginRight: 5 }}>

                                  </div>
                                )}
                                <p onClick={() => {
                                  setDataSeoStorage({
                                    meta_description: item2.meta_description,
                                    meta_keywords: item2.meta_keywords,
                                    title_page: item2.title_page,
                                    url: item2.url
                                  });
                                  NewQuestion(item2.id, item.id)
                                }} style={{ marginTop: 0, marginBottom: 0, cursor: 'pointer' }}>{item2.question}</p>
                              </div>
                            ))
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                  : <CircularProgress />}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default Blog
