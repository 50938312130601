import React, { useState, useEffect } from 'react';
import { IconButton, } from '@mui/material';
import ReactPlayer from 'react-player';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory, useParams } from 'react-router-dom'
import Requests from './Requests/RequestResp';
import { Carousel } from 'react-responsive-carousel';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from "react-html-parser";
import { AddHelmet } from '../../components/Helmet/AddHelmet';


const imgs = [
  { image: 'https://www.transporteinformativo.com.mx/wp-content/uploads/2020/03/kw-red-1.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2020/03/red-ambienta2-1024x597.jpg' },
  { image: 'https://wp.tyt.com.mx/wp-content/uploads/2019/11/red-ambiental-1-1024x597.jpg' },
]

function VerResp() {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  const { id } = useParams()
  let history = useHistory();
  const [noticia, setNoticia] = useState(null)
  const [fotos, setFotos] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    getNewById();

  }, [id]);

  const getNewById = async () => {
    try {
      Requests.getRespById(id).then((e) => {
        setNoticia(e)
        setFotos(e.photos)
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <div className="w-full inner-page-container-sp p-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {noticia !== null || noticia !== undefined ?
                <>
                  <Carousel
                    showArrows={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles,
                          left: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowBackIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    renderArrowNext={(onClickHandler, hasPrev, label) =>
                      fotos.length > 1 &&
                      <IconButton
                        onClick={onClickHandler}
                        size="large"
                        style={{
                          ...arrowStyles2,
                          right: "1.3em",
                          backgroundColor: "rgb(0, 61, 165)",
                          color: 'white',

                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: 25 }} style={{
                          alignSelf: 'center',
                          textAlign: 'center', display: 'flex'
                        }} />
                      </IconButton>
                    }
                    showIndicators={fotos.length > 1 ? true : false}
                    renderIndicator={(onClickHandler, isSelected, label) => (
                      <div className=" inline-block items-center justify-center ml-8">
                        <ul
                          style={{ ...indicatorStyles, opacity: isSelected ? '1' : '0.7' }}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}>
                          <li style={{ color: isSelected ? "rgb(0, 61, 165)" : "white", fontSize: 50 }}></li>
                        </ul>
                      </div>

                    )}
                    autoPlay
                    interval={3000}
                    infiniteLoop
                    swipeable
                    //  renderArrowNext={arrowNext}
                    emulateTouch
                    showThumbs={false}
                    showStatus={false}>
                    {fotos.map((data, index) => (
                      <div key={index} className="inner-section-carousel lazyload" data-bg={ `${mainUrl}/azure-storage/api/blob/download/?file=${data.photo.replace(/\s/g, "%20")}`} title={data.title}>

                      </div>
                    ))}
                  </Carousel>
                  <div className="w-full flex flex-col">
                    <div className="py-4 w-full lg:px-0">
                      <h1 className="">{noticia !== null ? noticia.program_name : null}</h1>
                    </div>
                    {noticia !== null ?
                      <div className="my-8 flex  justify-center flex-col items-center">
                        <div className="w-full">
                          <p className=" rich-text-editor">
                            {ReactHtmlParser(noticia !== null ? noticia.program_description : null)}
                          </p>
                        </div>
                        {noticia.url == "" ? null :
                          <div className=" w-100 my-2 blog-video-player">
                            <ReactPlayer controls width={"100%"} height={"100%"} url={noticia !== null ? noticia.url : null} />
                          </div>}

                      </div> : null}
                    <div className="py-2">
                      <h6 onClick={() => { history.push('/responsabilidad_social') }} className="detail-resp-social-btn-show-all">Ver todos los programas</h6>
                    </div>
                  </div>

                </> :
                <CircularProgress />}
            </div>
          </div>
        </div>
        <div className="flex-row w-full flex" style={{ borderColor: "rgb(151, 151, 151)" }}>
          <div className="w-2/12" style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `linear-gradient(rgba(0, 61, 165,.5) 0%, rgba(0, 61, 165,.5) 90%), url(${imgs[1].image})` }}>
          </div>

          <div className="w-2/12" style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `linear-gradient(rgba(0, 61, 165,.5) 0%, rgba(0, 61, 165,.5) 90%), url(${imgs[2].image})` }}>

          </div>
        </div>
      </div>
    </div>
  )
}

const indicatorStyles = {
  display: 'inline-block',
  color: '#fff',
  cursor: 'pointer',
};

const arrowStyles = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};

const arrowStyles2 = {
  position: "absolute",
  top: "45%",
  bottom: "auto",
  padding: ".4em",
  zIndex: 2,
};


export default VerResp