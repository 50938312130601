/** @format */

import React from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { range } from "lodash";
import { isMobile } from "react-device-detect";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} />
    </div>
  );
});

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className='page page-cover' ref={ref} data-density='hard'>
      <div className='page-content'>
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

export class Flip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      totalPage: 0,
      pagesForView: [1, 2, 3, 4]
    };
  }

  nextButtonClick = () => {
    this.flipBook.pageFlip().flipNext();
  };

  prevButtonClick = () => {
    this.flipBook.pageFlip().flipPrev();
  };

  onPage = (e) => {
    const currentPage = this.flipBook.pageFlip().getCurrentPageIndex();
    let newArray = this.state.pagesForView;

    if (currentPage > 0) {
      for (let index = currentPage; index < currentPage + 8; index++) {
        if (this.state.totalPage >= index && !newArray.includes(index)) newArray.push(index);
      }
    } else {
      newArray = [1, 2, 3];
    }

    //console.log('que hay de aquí?', newArray)
    this.setState({
      page: e.data,
      pagesForView: newArray
    });
  };

  componentDidUpdate() {
    /*this.setState({
        totalPage: this.flipBook?.getPageFlip().getPageCount(),
      });*/
  }

  handleLoadSuccess = (e) => {
    console.log("Success");
    this.setState({
      totalPage: e.numPages
    });
  };

  handleLoadError = (e) => {
    console.log("Error");
  };

  onChangeState = () => {
    //const currentPage = this.flipBook.pageFlip().getCurrentPageIndex();
    //console.log('cambiooooo')
  };

  render() {
    return (
      <div className='pdf-render-container'>
        <Document
          onLoadError={this.handleLoadError}
          className={!isMobile ? "d-none d-sm-block d-desktop-pdf" : "d-block d-sm-none d-mobile-pdf"}
          file={this.props.file}
          onLoadSuccess={this.handleLoadSuccess}
          loading='Cargando PDF...'
        >
          <HTMLFlipBook
            width={!isMobile ? 400 : 300}
            height={!isMobile ? 450 : 350}
            size='stretch'
            minWidth={!isMobile ? 400 : 200}
            maxWidth={400}
            minHeight={!isMobile ? 400 : 300}
            maxHeight={!isMobile ? 600 : 400}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
            onFlip={this.onPage}
            onChangeOrientation={this.onChangeOrientation}
            onChangeState={this.onChangeState}
            ref={(el) => (this.flipBook = el)}
          >
            {this.state.pagesForView.map((item) => (
              <Page pageNumber={item} key={item} />
            ))}
          </HTMLFlipBook>
        </Document>

        <div className='container-buttons'>
          <button type='button' onClick={this.prevButtonClick}>
            Página <br className='d-block d-md-none'></br>anterior
          </button>

          <div className='count-pages'>
            [<span>{this.state.page}</span> de
            <span>{this.state.totalPage}</span>]
          </div>

          <button type='button' onClick={this.nextButtonClick}>
            Siguiente <br className='d-block d-md-none'></br>página
          </button>
        </div>
      </div>
    );
  }
}
