import React, {useState, useEffect} from 'react';
import { IconButton,} from '@mui/material';
import Button from '@mui/material/Button';
import Select2 from 'react-select'
import TextField from '@material-ui/core/TextField';
import Requests from './Requests/RequestBlog';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useHistory } from 'react-router-dom'
import ReactHtmlParser from "react-html-parser";

  const imgs = [
    {image:'https://www.transporteinformativo.com.mx/wp-content/uploads/2020/03/kw-red-1.jpg'},
    {image:'https://wp.tyt.com.mx/wp-content/uploads/2020/03/red-ambienta2-1024x597.jpg'},
    {image:'https://wp.tyt.com.mx/wp-content/uploads/2019/11/red-ambiental-1-1024x597.jpg'},
]

function BolsaT() {
    let history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [estados,setEstados] = useState([])
    const [fullNews,setFullNews] = useState([])
    const [search,setSearch] = useState('')
    const [news,setNews] = useState([])
    const [estado,setEstado] = useState('')
    const open = Boolean(anchorEl);
    const regex = /(<([^>]+)>)/ig;
    const [bandera,setBandera] = useState(false)

      useEffect(()=>{
      window.scrollTo(0, 0)
      getNews()
      getStates()
       },[]);

       const getNews = async () => {
        try {
          Requests.getAlllNews().then((e)=>{
          //  console.log("NEWS---",e)
            setNews(e)
            setFullNews(e)
        }).catch((error)=>{
            console.log(error)
        })
        } catch (error) {
            console.log(error)
        }
      }
      const getStates = async () => {
        try {
          Requests.getAllStates().then((e)=>{
            //console.log("NEWS---",e)
            setEstados(e)
        }).catch((error)=>{
            console.log(error)
        })
        } catch (error) {
            console.log(error)
        }
      }
       const handleChangeEstados = (event) => {
      };

      const onDropDownCHange = (unidad) =>{
        //console.log("ESTADO",unidad)
        setEstado(unidad)
        }

        const busqueda = () => {

  
          setNews(fullNews.filter((e)=>{
            if(search == "" && estado == ''){
              return fullNews
            } 
            else if(search !== '' && estado !== ''){
              if((e.name.toLowerCase().includes(search.toLowerCase()) ||
              e.about.toLowerCase().includes(search.toLowerCase())) &&
              e.business_unit.map((value)=>(
              value.ubication.state.toLowerCase().includes(estado.state.toLowerCase())
              ))){
                return e
              }
            } else if(search !== '' && estado == ''){
              if(e.name.toLowerCase().includes(search.toLowerCase()) ||
              e.about.toLowerCase().includes(search.toLowerCase())){
                return e
              }
            }
            else if(search == '' && estado !== ''){
              const estado2 = estado.state
              //console.log(estado2)
              let filtro;
              e.business_unit.map((item)=>{
                if(item.ubication.state.includes(estado2)){
                 // console.log("ITEM4",e)
                  filtro = e
                  return e
                }
              return e
              })
              return filtro;
            }
          }))
          setEstado('')
          setSearch('')
        }
  


    return (
        <div>
            {/* <Header/> */}
            <div className="w-full inner-page-container p-4 background-custom">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <h1 className="mb-4">¡Forma parte de nuestro equipo de trabajo!</h1>
                    <TextField className="mb-2"
                    InputProps={{
                        disableUnderline: true,
                    }}
                    value={search}
                    type="search"
                    placeholder="Buscar"
                    onChange={(e)=>{setSearch(e.target.value)}}
                    variant="standard"
                    />
                    <Select2 className="mb-4"  options={estados} getOptionLabel={e=>e.state} getOptionValue={e=>e.id} value={estado} placeholder="Ubicación" onChange={onDropDownCHange} /> 
                    <button className="button btn-action-primary mb-4"
                    onClick={()=>{ busqueda(); setBandera(true)}} variant="contained">Buscar</button>
                     {bandera && 
                 <button className="button btn-action-secundary mb-4 mx-2"  
                onClick={()=>{setNews(fullNews); setBandera(false)}} variant="contained">Limpiar filtros</button>
                 }
                  </div>
                  <div className="col-md-8 offset-md-1">
                    {
                      news.length > 0 && (
                        news.filter((e)=>{
                          if(search == ""){
                            return e
                          } else if(e.name.toLowerCase().includes(search.toLowerCase()) || e.about.toLowerCase().includes(search.toLowerCase())){
                            return e
                          }
                        }).map((item,index)=>(
                            <div className="job-card mb-3">
                              <div className="card-block">
                                <div className="container-fluid g-0">
                                  <div className="row">
                                    <div className="col-sm-8">
                                      <IconButton size="small" className="icon-marker">
                                        <LocationOnIcon color="primary"/>
                                      </IconButton>
                                      {item.business_unit.map((e)=>(
                                        <h6 className="heading-city">{e.ubication.state}</h6>
                                      ))}
                                    </div>
                                    <div className="col-sm-4">
                                    <h6 className="date">{item.create_at}</h6>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="divider"></div>
                                    </div>
                                    <div className="col-sm-12">
                                      <h5>{item.name}</h5>
                                    </div>
                                    <div className="col-sm-12">
                                      <p className="job-description">{ReactHtmlParser(item.about)}</p>
                                    </div>
                                    <div className="col-sm-12">
                                      <Button className="btn-action-secundary job-card-button" autoCapitalize={false} endIcon={<ArrowRightAltIcon />} 
                                        onClick={()=>{
                                          history.push({
                                            pathname: '/vacantes',
                                            state: { detail: item.id }
                                        });
                                        }}>
                                        Ver vacante
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>    
                          )
                        )
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
             {/* <Footer/> */}
        </div>
    )
}
export default BolsaT;