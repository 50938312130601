import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getFaqs2 } from "./Requests/Requests";
import Button from '@mui/material/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function Faqs() {
    let history = useHistory();

    const [items, setItems] = useState([]);

    const handleClick = (data) => {
        setDataSeoStorage({
            meta_description: data.meta_description,
            meta_keywords: data.meta_keywords,
            title_page: data.title_page,
            url: data.url
        });
        history.push("/detalle_pregunta",
            {
                id_grouper: data.id_grouper,
                id: data.id,
            });
    };

    useEffect(async () => {
        if (window.location.hash == '#faqs-section') {
            setTimeout(() => {
                window.location.href = "#faqs-section"
            }, 1000);
        }
        const resp = await getFaqs2();
        setItems(resp);
    }, []);

    const setDataSeoStorage = (data) => {
        window.sessionStorage.setItem("seoRA", JSON.stringify(data));
    }

    return (
        <section id='faqs-section' className="section-home-faqs background-clients">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="heading-title mb-5">Preguntas frecuentes</h2>
                    </div>
                    <div className="col-sm-12">
                        <div className="faqs-container">
                            <div class="container-fluid">
                                <div className="row">
                                    {items.map((item, index) => (
                                        <div className="col-md-6 col-lg-4 mb-5" key={index}>
                                            <h3>{item.name}</h3>
                                            <ul className='mt-3'>
                                                {item.frequent_questions.map((child) => (
                                                    <li className="clickable"
                                                        onClick={() => handleClick(child)}
                                                        key={child.id}>
                                                        {child.question}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                    <div className='faqs-content-bottom'>
                                        {items.length > 0 &&
                                            <Button className="btn-action-secundary button-pressroom" endIcon={<ArrowRightAltIcon />}
                                                onClick={() => {
                                                    setDataSeoStorage({
                                                        meta_description: items[0].frequent_questions[0].meta_description,
                                                        meta_keywords: items[0].frequent_questions[0].meta_keywords,
                                                        title_page: items[0].frequent_questions[0].title_page,
                                                        url: items[0].frequent_questions[0].url
                                                    });
                                                    history.push("/detalle_pregunta",
                                                        {
                                                            id_grouper: items[0].frequent_questions[0].id_grouper,
                                                            id: items[0].frequent_questions[0].id,
                                                        });
                                                }}>Ver más</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faqs
