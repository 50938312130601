import React, {useState, useEffect, useRef} from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Requests from './Requests/RequestBlog';
import ReactHtmlParser from "react-html-parser";
export default function Vacantes() {
    let history = useHistory();
    const location = useLocation();
    const [noticia,setNoticia] = useState(null)
    const [ubicacion,setUbicacion] =useState(null)
    useEffect(()=>{
        window.scrollTo(0, 0)
       // console.log("ID",location.state.detail);
        getNewById()
         },[]);
  
         const getNewById = async () => {
          try {
            Requests.getNewID(location.state.detail).then((e)=>{
              e.business_unit.map((i)=>{
                  setUbicacion(i.ubication.state)
              })
              setNoticia(e)
          }).catch((error)=>{
              console.log(error)
          })
          } catch (error) {
              console.log(error)
          }
        }
    return (
        <div>
            { noticia !== null || noticia !== undefined   ?
            <>
                <div className="w-full inner-page-container p-4 background-custom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 mb-4">
                                <Button autoCapitalize={false} className="vacancy-button"  startIcon={<ArrowBackIcon />} 
                                onClick={()=>{
                                    history.push('/bolsa_trabajo');
                                }}>
                                Regresar
                                </Button>
                            </div>
                            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                                <div className="job-card mb-4">
                                    <div className="card-block">
                                        <div className="container-fluid g-0">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <IconButton className="icon-marker" size="small">
                                                        <LocationOnIcon color="primary"/>
                                                    </IconButton>
                                                    <h6 className="heading-city">{noticia !== null ? ubicacion:null}</h6>
                                                    <h6 className="date">{noticia !== null ? noticia.create_at:null}</h6>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="divider"></div>
                                                </div>
                                                <div className="col-sm-12 mb-3">
                                                    <h5>{noticia !== null ?noticia.name:null}</h5>
                                                </div>
                                                <div className="col-sm-12">
                                                    <h6>Propósito General</h6>
                                                    <p className="job-description mb-4">{noticia !== null ? ReactHtmlParser(noticia.about): null}</p>
                                                </div>
                                                <div className="col-sm-12">
                                                    <h6>Reponsabilidades principales</h6>
                                                    <p className="job-description mb-4">{noticia !== null ? ReactHtmlParser(noticia.responsibilities):null}</p>
                                                </div>
                                                <div className="col-sm-12">
                                                    <h6>Requisitos generales</h6>
                                                    <p className="job-description mb-4">{noticia !== null ? ReactHtmlParser(noticia.requirements):null}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">

                            </div>
                        </div>
                    </div>
                </div>
            </>:
            <CircularProgress/>}
        </div>
    )
}


