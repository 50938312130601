import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom'
import CarruselRequest from './Requests/CarruselRequest'
import Secciones from '../../pages/InfoInst/Requests/InfoRequests';
import ReactHtmlParser from "react-html-parser";
import LazyHydrate from "react-lazy-hydration";

const Carrusel = () => {

    let history = useHistory();
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL;
    const [images,setImages] = useState([])
    const [indicadores,setIndicadores] = useState([])
    const [secciones,setSecciones] = useState([])

    useEffect(()=>{
        Indicators()
        getSecciones()
        try {
        CarruselRequest.getAllEmployees().then((e)=>{
            setImages(e)
        }).catch((error)=>{
            //console.log(error)
        })
        } catch (error) {
            //console.log(error)
        }
     },[]);

     const getSecciones = () => {
        try {
            Secciones.getAllInformationInst().then((res)=>{
            //console.log('Secciones',res)
            setSecciones(res)
        })
        } catch (error) {
        //console.log(error)
        }
    }

     const Indicators = () => {
         try {
            CarruselRequest.getIndicators().then((e)=>{
           //     console.log("RES",e)
                setIndicadores(e)
            }).catch((error)=>{
             //   console.log(error)
            })
         } catch (error) {
             
         }
     }


    return (
        <LazyHydrate whenVisible>
        <div className="slider-home">
            {images.length > 0 && (
                <div>
                    <div className="slider-container">
                        <Carousel className="slider-carousel"
                        showArrows
                        showIndicators={false}
                        autoPlay
                        interval={6000}
                        infiniteLoop
                        swipeable
                        emulateTouch
                        //	${mainUrl}/azure-storage/api/blob/download/?file=20220429154905-0000000195.jpg
                        showThumbs={false}
                        showStatus={false}>
                        {images.map((data,index)=>(
                            <div title={data.title} key={`carousel-image${data.id_carousel}`} className="slide lazyload filtro" 
                            style={{backgroundImage:`url(${mainUrl}/azure-storage/api/blob/download/?file=${data.name_image.replace(" ", "%20" )})`}}></div>
                        ))}
                        </Carousel>
                        <div className="slider-text">                            
                            <Button className="button-home" onClick={()=>{
                            history.push(`/nuestra_empresa/${encodeURIComponent(secciones[0].url)}`)}}>¡Conócenos!</Button>
                            <h1 className="slide-title">La empresa de recolección y aprovechamiento de residuos número 1 en México</h1>
                        </div>    
                    </div>
                    <div className="indicators-container" >
                        {indicadores.length > 0 &&
                        indicadores.map((item,index)=>(
                        <div key={`carousel-indicadores${item.id}`} className="indicators-single">
                            <img src={`${mainUrl}/azure-storage/api/blob/download/?file=${item.name_image.replace(" ", "%20" )}`} alt={item.alt} title={item.title}  className="lazyload"/>
                            <p id="indicadores" className="text-indicator">{ReactHtmlParser(item.description)}</p>
                        </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
        </LazyHydrate>
    );
  
}

export default Carrusel;