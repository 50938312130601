import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import {
    CropOriginal,
    PictureAsPdfOutlined
} from '@mui/icons-material';
import {
    getAll,
    getallImages,
    getallFiles
} from "./Requests/Requests";
import "../../css/three-dots.min.css";
import { generateZip, tagImages } from './Utils/zip';
import { IconButton } from '@mui/material';
import Email from '@mui/icons-material/Email';

export default () => {
    const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
    let history = useHistory();

    const [count, setCount] = useState(1);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const downloadAll = async (item) => {
        const imgs = await getallImages(item.id);
        const files = await getallFiles(item.id);
        const resp = [...tagImages(imgs), ...files];
        generateZip(resp, 'archivos.zip');
    }

    const downloadImages = async (item) => {
        const resp = await getallImages(item.id);
        generateZip(tagImages(resp), 'imagenes.zip');
    }

    const downloadFiles = async (item) => {
        const resp = await getallFiles(item.id);
        generateZip(resp, 'archivos.zip');
    }

    window.onscroll = async function (ev) {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight
            && !loading && window.location.href.indexOf("prensa") > -1) {
            //setLoading(true);
            const resp = await getAll(count, 8);
            setItems(i => [...i, ...resp]);
            setCount(i => i + 1);
            setLoading(false);
        }
    };

    const handleClick = (id) => {
        history.push(`/detalle_prensa/${encodeURIComponent(id)}`);
    };

    useEffect(async () => {
        const resp = await getAll(0, 8);
        setLoading(false);
        setItems(resp);
    }, []);


    const setDataSeoStorage = (data) => {
        window.sessionStorage.setItem("seoRA", JSON.stringify(data));
    }


    return (
        <div>
            <div className="inner-page-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="heading-title pt-2 pb-5">
                                Sala de Prensa
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        {items.map((item, index) => (
                            <div className="col-md-12 col-lg-6">

                                <div key={item.id} onClick={() => {
                                    handleClick(item.url);
                                    setDataSeoStorage({
                                        meta_description: item.meta_description,
                                        meta_keywords: item.meta_keywords,
                                        title_page: item.title_page,
                                        url: item.url
                                    });
                                }} style={{ cursor: 'pointer' }} className="news-card mb-4">
                                    <div class="container-fluid g-0">
                                        <div className="row g-0">
                                            <div className="col-sm-4">
                                                <div className="img-container" style={{ backgroundImage: `url(${mainUrl}/azure-storage/api/blob/download/?file=${item.name_image.replace(/\s/g, "%20")})`, }}></div>
                                            </div>
                                            <div className="col-sm-8 card-block">
                                                <h5 className="news-card-title mb-4">{item.title}</h5>
                                                <p className="news-card-date">{item.date_notice}</p>
                                                <div className="news-buttons-container">
                                                    {item.count === 'Both' &&
                                                        <button
                                                            className="button btn-action-primary mb-4"
                                                            variant="contained"
                                                            onClick={() => downloadAll(item)}>
                                                            Descargar todo
                                                        </button>
                                                    }

                                                    {['Both', 'Images Only'].includes(item.count) &&
                                                        <button
                                                            className="button btn-action-secundary mb-4"
                                                            variant="contained"
                                                            onClick={() => downloadImages(item)}>

                                                            <CropOriginal className="mr-2" />
                                                            Descargar imágenes
                                                        </button>
                                                    }

                                                    {['Both', 'Files Only'].includes(item.count) &&
                                                        <button
                                                            className="button btn-action-secundary mb-4"
                                                            variant="contained"
                                                            onClick={() => downloadFiles(item)}>
                                                            <PictureAsPdfOutlined className="mr-2" />
                                                            Descargar pdf
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        {loading &&
                            <div className="col-sm-12 text-center">
                                <div className="dot-elastic"></div>
                            </div>
                        }
                    </div>
                    <div class="row pt-4 pb-4">
                        <div className='col-md-6 offset-md-3'>
                            <div className='info-prensa'>
                                <h2 className="">
                                    Contacto de prensa:
                                </h2>
                                <IconButton size="large" edge="start" color="inherit" aria-label="menu"> <Email /></IconButton><a className="taga-text-decorate" href={`mailto:noticias@redambiental.com`}>noticias@redambiental.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
