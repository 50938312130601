import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const BusinessUrl = `${mainUrl}/web-page-conf/web/business-line/services`
const ServicesUrl = `${mainUrl}/web-page-conf/web/business-line/detail`

//lineas de negocio
const getAll = async () => {
  const { data: res } = await axios.get(`${BusinessUrl}`);
  return res;
};
const getAll2 = async () => {
  const { data: res } = await axios.get(`${BusinessUrl}`);
  return res;
};

const getById = async (id) => {
  const { data: res } = await axios.get(`${ServicesUrl}/${id}`);
  return res;
};
const getById2 = async (id) => {
  const { data: res } = await axios.get(`${BusinessUrl}/${id}`);
  return res;
};


export default {
  getAll,
  getAll2,
  getById2,
  getById
}